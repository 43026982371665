const tickets = {
  transfer: {
    title: 'Navegador de transferencia',
    filterTitle: 'Examinar transferencias',
    transferDetail: 'Transferencia',
    subtitle: 'Transferir Agregar elementos',
    keepPageOpen: 'Mantener la página abierta',
    printTransfer: 'Imprimir transferencia cuando se procesa',
    actionHeader: {
      0: {
        title: 'Crear transferencia',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Exportar datos',
        icon: 'fas fa-file-export'
      },
      2: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      }
    },
    subActionHeader: {
      0: {
        title: 'Guardar como borrador',
        isEditing: false,
        isDraft: true,
        isShow: true,
        icon: 'fas fa-save'
      },
      1: {
        title: 'Transferencia de proceso',
        isEditing: false,
        isDraft: true,
        isShow: true,
        icon: 'fas fa-save'
      },
      2: {
        title: 'Editar transferencias',
        isEditing: true,
        isDraft: false,
        isShow: true,
        isOptional: true,
        icon: 'fas fa-edit'
      },
      3: {
        title: 'Agregar registro de herramienta',
        isShow: true,
        isEditing: false,
        isDraft: true,
        icon: 'fas fa-plus'
      },
      4: {
        title: 'Agregar registro de material',
        isShow: true,
        isEditing: false,
        isDraft: true,
        icon: 'fas fa-plus'
      },
      5: {
        title: 'Atrás',
        isShow: true,
        isEditing: true,
        isDraft: true,
        icon: 'fas fa-undo'
      },
      6: {
        title: 'Eliminar borrador de transferencia',
        isEditing: false,
        isShow: false,
        isDraft: true,
        icon: 'fa fa-trash'
      },
      7: {
        title: 'Colaborar',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isDraft: true,
        isShow: true
      }
    },
    browserLineActions: [
      {
        title: 'Crear transferencia',
        actionName: 'createTransfer',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Exportar datos',
        actionName: 'exportData',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Imprimir',
        actionName: 'printData',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Abrir',
        actionName: 'viewTransfer',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Borrar',
        actionName: 'deleteTransfer',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    sidePanel: {
      labels: {
        0: 'Todas las herramientas',
        1: 'Todos los materiales',
        2: 'Todas las categorias',
        3: 'Todo el equipamiento'
      }
    },
    cart: {
      desc: 'Elementos en el boleto de transferencia',
      labels: {
        0: 'Transferir',
        1: 'Elija el boleto',
        2: 'Registrar exprés',
        3: 'Verificar exprés',
        4: 'Transferido por',
        5: 'Asignado en',
        6: 'Entregado por',
        7: 'Fecha de regreso',
        8: 'Documento núm.',
        9: 'Escanear o ingresar numero de artículo',
        10: 'Transferido de',
        11: 'Centro de costos desde',
        12: 'Estado',
        13: 'Dueño',
        14: 'Centro de costos a',
        15: 'Código de costo',
        16: 'Medidor',
        17: 'Transferir a',
        18: 'Centro de costos',
        19: 'Personal responsable',
        20: 'Regreso a una ubicación de almacen',
        21: 'Imprimir Transferencia',
        22: 'Explotar Equipos',
        23: 'Estado',
        24: 'Fuente'
      }
    },
    process: {
      kitWarning: 'Este artículo está en un equipo. ¿Le gustaría remover el artículo del equipo o transferir el equipo completo?',
      noTransferWarning: '{desc} - {number} tiene un estatus de No Transferir. ¿Desea anular?',
      removeWarning: {
        0: 'No puedes eliminar esta línea ya que forma parte de un equipo.',
        1: 'Debes tener elementos de transferencia agregados para procesar',
        certification: {
          title: 'Problemas de certificación(es)',
          message: 'Uno o más problemas de certificación más están presentes :\n{message} \n ¿Desea continuar?'
        }
      },
      labels: {
        0: 'Mantener Asociación',
        1: 'Romper del equipo',
        2: 'Equipo de transferencia',
        3: 'No transferir'
      },
      expressZeroInWarning: 'Los siguientes artículos no serán transferidos. ¿Desea continuar?',
      transferHasZeroQuantityLinesError: 'No se pueden enviar líneas de transferencia con cantidad 0, ajuste su transferencia.'
    },
    scan: {
      item: 'Se encontraron varios artículos con ese número. Elija un elemento y haga clic en Aceptar.',
      model: 'Se encontraron varios modelos con ese número. Elija un modelo y haga clic en Aceptar.'
    },
    filter: {
      0: 'Transferencia No.',
      1: 'Transferencias desde',
      2: 'Transferencias a',
      3: 'Fechas de asignación desde'
    },
    report: {
      title: 'Reporte de boleto de transferencia'
    },
    status: {
      new: 'Nuevo',
      draft: 'Corriente de aire',
      processed: 'Procesado'
    },
    negativeWarning: 'Esta transacción creará un valor de almacenamiento negativo',
    editTransferNote: 'Los cambios realizados en los centros de costos pueden generar flujos de facturación duplicados, si los cargos por esta transferencia ya se han publicado. Revise la facturación publicada antes de realizar cambios. Los cambios en los códigos de costo solo se aplicarán si la transferencia aún no se ha publicado.',
    lastBleDontMatchAssignedTo: 'La última ubicación de escaneo inalámbrico BLE no coincide con la asignación del elemento.',
    bleNotActive: 'Este artículo tiene una etiqueta inalámbrica BLE y no se ha escaneado en los últimos 30 días.',
    bleActive: 'Este artículo tiene una etiqueta inalámbrica BLE y ha sido escaneado en los últimos 30 días.',
    draftSave: 'Borrador guardado exitosamente',
    scheduleTransfer: {
      title: 'Advertencia',
      info: `El modelo para los elementos siguientes es necesario para programar solicitudes.
        Esta transferencia reducirá el inventario por debajo de la cantidad necesaria.`,
      confirm: '¿Desea continuar?'
    }
  },
  pick: {
    title: 'Elija el navegador de entradas',
    filterTitle: 'Examinar Boleto de Solicitudes',
    subtitle: 'Elija el boleto',
    actionHeader: {
      0: {
        title: 'Crear Boleto',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Elija este boleto',
        icon: 'fas fa-edit'
      }
    },
    subActionHeader: {
      printTooltip: 'El boleto de selección debe guardarse antes de imprimir',
      0: {
        title: 'Guardar',
        icon: 'fas fa-save',
        isShow: true
      },
      1: {
        title: 'Imprimir',
        key: 'Print',
        icon: 'fas fa-print',
        isShow: true
      },
      2: {
        title: 'Agregar Plantilla',
        icon: 'fas fa-plus',
        isShow: true
      },
      3: {
        title: 'Agregar registro de herramienta',
        icon: 'fas fa-plus',
        isShow: true
      },
      4: {
        title: 'Agregar registro de material',
        icon: 'fas fa-plus',
        isShow: true
      },
      5: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip',
        isShow: true
      },
      6: {
        title: 'Atrás',
        icon: 'fas fa-undo',
        isShow: true
      },
      7: {
        title: 'Estado a Borrador',
        action: 'statusToDraft',
        icon: 'fa fa-arrow-left',
        isShow: true
      },
      8: {
        title: 'Eliminar borrador de selección',
        icon: 'fa fa-trash',
        isShow: false
      },
      9: {
        title: 'Colaborar',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true
      }
    },
    browserLineActions: [
      {
        title: 'Vista',
        actionName: 'viewTicketAction',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Borrar',
        actionName: 'deleteTicket',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Imprimir',
        actionName: 'printData',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Estado a Borrador',
        actionName: 'statusToDraft',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Reabrir Ticket de Recogida',
        actionName: 'statusToOpen',
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ],
    filter: {
      0: 'Abierto',
      1: 'Cerrado',
      2: 'Todo',
      3: 'Número de boleto de Solicitud',
      4: 'Solicitado para',
      5: 'Asignado a’',
      6: 'Estado',
      7: 'Fecha de creación',
      8: 'Solicitado desde',
      9: 'Solicitado por',
      10: 'Asignación actual'
    },
    ticketHolder: {
      labels: {
        0: 'Elegir de',
        1: 'Elegir para',
        2: 'Numero de requisición',
        3: 'Solicitado por',
        4: 'Fecha de vencimiento',
        5: 'Asignado para',
        6: 'Fecha de cierre',
        7: 'Elegir resumen de recibo',
        8: '¿Transferir artículos seleccionados?',
        9: 'Lista de selección',
        10: 'Artículos seleccionados',
        11: 'Pregunta',
        12: '¿Cerrar este ticket?',
        13: '¿Listo para Seleccionar?',
        14: 'Fecha de regreso'
      }
    },
    ticketItem: {
      title: 'Elementos en el ticket de selección',
      labels: {
        0: 'Genérico'
      }
    },
    pickedItemDetail: {
      title: 'Detalle del artículo elegido',
      category: {
        0: {
          name: 'Artículo',
          labels: {
            0: 'Número de artículo',
            1: 'Cant.',
            2: 'Descripción',
            3: 'Fabricante',
            4: 'Modelo'
          }
        },
        1: {
          name: 'Seleccionado',
          labels: {
            0: 'Seleccionado por',
            1: 'Fecha',
            2: 'Hora',
            3: 'Solicitado desde',
            4: 'Solicitado para',
            5: 'Centro de costos',
            6: 'Código de costo'
          }
        },
        3: {
          name: 'Transferir',
          labels: {
            0: 'Número de transferencia.',
            1: 'Transferido por',
            2: 'Fecha',
            3: 'Hora',
            4: 'Desde',
            5: 'Para'
          }
        }
      },
      confirmDialog: {
        title: {
          0: 'Artículo sustituto',
          1: '¿reintegrarlo?'
        },
        text: {
          0: 'El artículo no coincide con la línea seleccionada. ¿Quieres sustituirlo por este artículo?',
          1: 'Este {desc} ha sido retirado. ¿le gustaría reintegrarlo?',
          2: 'Está a punto de restablecer las herramientas. \n Esto significa que la herramienta se eliminará de la lista Herramientas retiradas y se volverá a poner en servicio normal.',
          3: 'Restablecer a',
          4: 'Estado'
        }
      }
    },
    warning: {
      0: 'No puede Seleccionar un boleto en el estado {status}',
      1: 'Confirmar la eliminación del Boleto',
      2: '¿Estás seguro de que quieres eliminar este boleto?',
      3: 'No puede eliminar los boletos que se han elegido.',
      4: 'Elija este boleto?',
      5: 'El boleto está \'Listo para elegir\'. ¿Le gustaría recoger este boleto?',
      6: 'No debe elegir este boleto. Actualmente está siendo elegido por otro usuario. \n ¿Elija este boleto de todos modos?',
      noLineforTransfer: 'No hay artículos para transferir',
      invalidTitle: 'Cantidad no válida',
      invalidMessage: 'El artículo {itemNumber} es una herramienta única. solo uno fue elegido'
    }
  },
  templates: {
    title: 'Navegador de plantillas',
    fly_up: {
      title: 'Modelos en la plantilla',
      desc: 'Modelos en la plantilla',
      labels: {
        0: 'Genérico'
      }
    },
    actionHeader: {
      0: {
        title: 'Crear plantilla',
        icon: 'fas fa-plus'
      }
    },
    subActionHeader: {
      0: {
        title: 'Agregar registro de herramienta',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Agregar registro de material',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      },
      3: {
        title: 'Impresión',
        icon: 'fas fa-print'
      }
    },
    dlg: {
      title: 'Nuevo tipo de plantilla'
    },
    holder: {
      title: {
        edit: 'Detalle de plantilla',
        create: 'Nueva plantilla'
      },
      labels: {
        0: 'Plantilla No.',
        1: 'Tipo de plantilla',
        2: 'Categoría de plantilla',
        3: 'Descripción de plantilla',
        4: 'Nota',
        5: 'Resumen de plantilla',
        6: 'Seleccionar modelo'
      },
      dlgLabels: {
        0: '¿Está seguro de querer eliminar el siguiente elemento de esta plantilla?',
        1: 'Número: ',
        2: 'Descripción: ',
        3: 'Error...',
        4: 'Debe tener al menos un elemento agregado a su plantilla antes de guardar',
        6: 'Incapaz de añadir...',
        7: 'Debe seleccionar un Nombre y una categoría antes de agregar elementos a la plantilla.',
        8: 'No puede eliminar el último elemento de una plantilla. Si desea eliminar toda la plantilla, hágalo desde el navegador de plantillas.',
        9: '¿Está seguro de que desea eliminar la plantilla:'
      }
    }
  },
  countInventory: {
    title: 'Contar navegador de recibos',
    actionHeader: {
      0: {
        title: 'Crear contador de recibos',
        tooltip: 'Crear contador de recibos',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Navegador de ajustes de inventario',
        tooltip: 'Navegador de ajustes de inventario',
        icon: 'fas fa-list'
      },
      2: {
        title: 'Imprimir',
        tooltip: 'Imprimir',
        icon: 'fas fa-print'
      }
    },
    subActionHeader: {
      0: {
        title: 'Guardar',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Agregar registro de herramienta',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Agregar registro de material',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Exportar',
        icon: 'fas fa-file-export'
      },
      4: {
        title: 'Atrás',
        icon: 'fas fa-undo'
      }
    },
    browserLineActions: [
      {
        title: 'Vista',
        actionName: 'editCountTicket',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Eliminar',
        actionName: 'deleteCountTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    inventoryAdjLineActions: [
      {
        title: 'Editar',
        actionName: 'editInventoryItem',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Ver boleto',
        actionName: 'viewTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Agregar a la transferencia',
        actionName: 'addToTransfer',
        requiresSelection: true,
        allowsMultiSelect: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        permissions: ['TransfersExecute']
      },
      {
        title: 'Herramienta de retirada',
        actionName: 'retireTool',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        isShown: function (items) {
          if (!items.length) return false
          return 'lastScanLocation' in items[0]
        },
        permissions: ['ToolDetailView', 'MaterialDetailView']
      }
    ],
    inventoryAdjustmentsActionHeader: {
      0: {
        title: 'Ajustes de publicación',
        tooltip: 'Ajustes de publicación',
        icon: 'fas fa-sliders-h'
      },
      1: {
        title: 'Impresión',
        tooltip: 'Impresón',
        icon: 'fas fa-print'
      }
    },
    labels: {
      0: 'Navegador de boletos de Inventario',
      1: 'Contar ubicación:',
      2: 'Número de recibo:',
      3: 'Resumen:',
      4: 'Fecha de creación:',
      5: 'Para',
      6: 'Abierto Tipo:',
      7: 'Todo',
      8: 'Abierto',
      9: 'Cerrado',
      10: 'Boleto de Conteo',
      11: 'Estado:',
      12: 'Creado:',
      13: 'Cant',
      14: 'Confirmar eliminación',
      15: 'Resumen de Conteo',
      16: 'Artículos contados',
      17: 'Resumen de recibo',
      18: 'Navegador de ajustes de inventario',
      19: 'Este artículo se contó en una ubicación diferente a su asignación actual',
      20: 'Contado',
      21: 'No - contado',
      22: 'Todo',
      23: 'Detalle de ajuste',
      24: 'Detalle de línea',
      25: 'Contar:',
      26: 'Cuenta total:',
      27: 'Nota:',
      28: 'Ajustes de publicación',
      29: 'Procesar?',
      30: 'Filtrar por Ubicación',
      31: 'Crear Transferencia',
      32: 'Procesar giro de transferencia'
    },
    toolRecord: {
      title: 'Registro de la herramienta',
      actionHeader: {
        0: {
          title: 'Retirar herramienta',
          tooltip: 'Retirar herramienta',
          icon: 'fas fa-tools'
        },
        1: {
          title: 'Adjuntos',
          tooltip: 'Adjuntos',
          icon: 'fas fa-paperclip'
        }
      },
      labels: {
        0: 'ID de modelo:',
        1: 'Información del fabricante y modelo',
        2: 'Fabricante:',
        3: 'Categoría:',
        4: 'Modelo:',
        5: 'Tu descripción:',
        6: 'Descripción del fabricante:',
        7: 'Método de seguimiento',
        8: 'Contenedor:',
        9: 'Ver información del modelo',
        10: 'Número de herramienta',
        11: 'Código de barras',
        12: 'Artículo de problema personal',
        13: 'Notas',
        14: 'Conteo de órdenes de trabajo',
        15: 'Cargos totales',
        16: 'Información de compra para ubicación de almacen:',
        17: 'Información de costos',
        18: 'Cantidad Total Comprado',
        19: 'Costo promedio de compra',
        20: 'Costo de la última compra',
        21: 'Costo de remplazo',
        22: 'Vendedor Preferido',
        23: 'informacion de compra',
        24: 'Fecha de compra',
        25: 'Costo de la compra',
        26: 'Número de orden de compra',
        27: 'Vencimiento de la garantía',
        28: 'Resumen Modelo'
      },
      tabs: [
        'Información de la herramienta',
        'Piezas de reparación',
        'Componentes',
        'Accesorios',
        'Servicio',
        'Información de compra y costo',
        'Arrendamiento / Alquiler'
      ],
      toolInfoTabLabels: {
        0: 'Información de asignación actual',
        1: 'Asignado a',
        2: 'Dueño',
        3: 'Gerente',
        4: 'Cant.',
        5: 'Estado',
        6: 'Fecha de regreso',
        7: 'ID del Equipo',
        8: 'Personal responsable',
        9: 'Información de herramienta única',
        10: 'Número de serie',
        11: 'Departamento',
        12: 'Clase',
        13: 'Información del vehículo',
        14: 'Etiqueta de licencia',
        15: 'Estado',
        16: 'Vencimiento',
        17: 'Tarjeta de combustible',
        18: 'Medidor / odómetro',
        19: 'Medidor',
        20: 'Notas',
        21: 'Artículo de problema personal'
      },
      serviceTabs: [
        'Plan de servicio',
        'Órdenes de trabajo abiertas',
        'Historial de servicio'
      ],
      serviceRecurrenceTypes: [
        '',
        'Por fecha',
        'Por Medidor',
        'Por turnos',
        'Primera aparición',
        ''
      ],
      serviceRecurrenceSequenceString: [
        '',
        'primero',
        'segundo',
        'tercera',
        'cuatro',
        'quinto',
        'sexto',
        'séptimo',
        'octavo',
        'noveno',
        'décimo',
        'undécimo',
        'duodécimo'
      ],
      serviceRecurrenceMonthString: [
        '',
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Diciembre',
        'Noviembre',
        'Diciembre'
      ],
      repairePartsTblHeader: {
        desktop: [
          {
            label: 'Descripción',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Requerido',
            key: 'Required',
            sortable: true
          },
          {
            label: 'Precio cada uno',
            key: 'Price Each',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Información',
            data: [
              {
                label: 'Descripción',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Fabricante',
                key: 'Manufacturer',
                sortable: true
              },
              {
                label: 'Requerido',
                key: 'Required',
                sortable: true
              },
              {
                label: 'Precio cada uno',
                key: 'Price Each',
                sortable: true
              }
            ]
          }
        ]
      },
      servicePlanTblHeader: {
        desktop: [
          {
            label: 'Eventos de servicio',
            key: 'description',
            sortable: true
          },
          {
            label: 'Periódica',
            key: 'recurring',
            sortable: true
          },
          {
            label: 'Método de ocurrencia',
            key: 'recurrenceMethod',
            sortable: true
          },
          {
            label: 'Activar',
            key: 'triggerOn',
            sortable: true
          },
          {
            label: 'Nota',
            key: 'note',
            sortable: true
          },
          {
            label: '',
            key: 'action',
            sortable: false
          }
        ],
        mobile: [
          {
            label: 'Información',
            data: [
              {
                label: 'Eventos de servicio',
                key: 'description',
                sortable: true
              },
              {
                label: 'Periódica',
                key: 'recurring',
                sortable: true
              },
              {
                label: 'Método de recurrencia',
                key: 'recurrenceMethod',
                sortable: true
              },
              {
                label: 'Activar',
                key: 'triggerOn',
                sortable: true
              },
              {
                label: 'Nota',
                key: 'note',
                sortable: true
              }
            ]
          },
          {
            label: '',
            key: 'action',
            sortable: false
          }
        ]
      },
      serviceOpenWorkOrdersTblHeader: {
        desktop: [
          {
            label: 'Número',
            key: 'No',
            sortable: true
          },
          {
            label: 'Prioridad',
            key: 'Priority',
            sortable: true
          },
          {
            label: 'Condicion',
            key: 'Status',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'State',
            sortable: true
          },
          {
            label: 'Nota de solicitud de servicio',
            key: 'Service Request Note',
            sortable: true
          },
          {
            label: 'Creado',
            key: 'Created',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Información',
            data: [
              {
                label: 'Número',
                key: 'No',
                sortable: true
              },
              {
                label: 'Prioridad',
                key: 'Priority',
                sortable: true
              },
              {
                label: 'Condicion',
                key: 'Status',
                sortable: true
              },
              {
                label: 'Estado',
                key: 'State',
                sortable: true
              },
              {
                label: 'Nota de solicitud de servicio',
                key: 'Service Request Note',
                sortable: true
              },
              {
                label: 'Creado',
                key: 'Created',
                sortable: true
              }
            ]
          }
        ]
      },
      serviceServiceHistoryTblHeader: {
        desktop: [
          {
            label: 'Orden de trabajo',
            key: 'Work Order',
            sortable: true
          },
          {
            label: 'Fecha de Terminación',
            key: 'Completion Date',
            sortable: true
          },
          {
            label: 'Servicio solicitado',
            key: 'Requested Service',
            sortable: true
          },
          {
            label: 'Nota de solicitud de servicio',
            key: 'Service Request Note',
            sortable: true
          },
          {
            label: 'Cargos',
            key: 'Charges',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Información',
            data: [
              {
                label: 'Orden de trabajo',
                key: 'Work Order',
                sortable: true
              },
              {
                label: 'Fecha de Terminación',
                key: 'Completion Date',
                sortable: true
              },
              {
                label: 'Servicio solicitado',
                key: 'Requested Service',
                sortable: true
              },
              {
                label: 'Nota de solicitud de servicio',
                key: 'Service Request Note',
                sortable: true
              },
              {
                label: 'Cargos',
                key: 'Charges',
                sortable: true
              }
            ]
          }
        ]
      },
      leaseRentalTblHeader: {
        desktop: [
          {
            label: 'Vendedor',
            key: 'Vendor',
            sortable: true
          },
          {
            label: 'ID de unidad de proveedor',
            key: 'UnitId',
            sortable: true
          },
          {
            label: 'Contrato',
            key: 'Contract',
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'TypeDescription',
            sortable: true
          },
          {
            label: 'Solicitud',
            key: 'Requisition',
            sortable: true
          },
          {
            label: 'Comienzo',
            key: 'StartDate',
            sortable: true
          },
          {
            label: 'Fin',
            key: 'EndDate',
            sortable: true
          },
          {
            label: 'Recordatorio',
            key: 'Reminder',
            sortable: true
          },
          {
            label: 'Plazo del arrendamiento',
            key: 'Term',
            sortable: true
          },
          {
            label: 'Monto del arrendamiento',
            key: 'LeaseAmount',
            sortable: true
          },
          {
            label: 'Valor residual',
            key: 'Residual',
            sortable: true
          },
          {
            label: 'Índice',
            key: 'Rate',
            sortable: true
          },
          {
            label: 'Unidad de tarifa',
            key: 'RateUnitDescription',
            sortable: true
          },
          {
            label: 'Comentarios',
            key: 'Comments',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Información',
            data: [
              {
                label: 'Vendedora',
                key: 'Vendor',
                sortable: true
              },
              {
                label: 'ID de unidad de proveedor',
                key: 'UnitId',
                sortable: true
              },
              {
                label: 'Contrato',
                key: 'Contract',
                sortable: true
              },
              {
                label: 'Tipo',
                key: 'TypeDescription',
                sortable: true
              },
              {
                label: 'Solicitud',
                key: 'Requisition',
                sortable: true
              },
              {
                label: 'Comienzo',
                key: 'StartDate',
                sortable: true
              },
              {
                label: 'Fin',
                key: 'EndDate',
                sortable: true
              },
              {
                label: 'Recordatorio',
                key: 'Reminder',
                sortable: true
              },
              {
                label: 'Plazo del arrendamiento',
                key: 'Term',
                sortable: true
              },
              {
                label: 'Monto del arrendamiento',
                key: 'LeaseAmount',
                sortable: true
              },
              {
                label: 'Valor residual',
                key: 'Residual',
                sortable: true
              },
              {
                label: 'Índice',
                key: 'Rate',
                sortable: true
              },
              {
                label: 'Unidad de tarifa',
                key: 'RateUnitDescription',
                sortable: true
              },
              {
                label: 'Comentarios',
                key: 'Comments',
                sortable: true
              }
            ]
          }
        ]
      },
      contractDetailsDlg: {
        title: 'Detalles del contrato',
        labels: {
          0: 'Proveedor',
          1: 'ID de unidad de proveedor',
          2: 'Contrato',
          3: 'Tipo',
          4: 'Solicitud',
          5: 'Fecha de inicio',
          6: 'Fecha de finalización',
          7: 'Fecha de recordatorio',
          8: 'Detalles del arrendamiento',
          9: 'Plazo de arrendamiento',
          10: 'Monto del arrendamiento',
          11: 'Valor residual',
          12: 'Detalles del alquiler',
          13: 'Tarifa de alquiler',
          14: 'Unidad de tarifa',
          15: 'Comentarios',
          16: '¡Los detalles del contrato se guardaron correctamente!'
        },
        contractTypes: [
          {
            text: 'Alquiler',
            value: '1'
          },
          {
            text: 'Alquiler para poseer',
            value: '2'
          }
        ],
        rateUnitTypes: [
          {
            text: 'Por hora',
            value: '1'
          },
          {
            text: 'Diario',
            value: '2'
          },
          {
            text: 'Semanalmente',
            value: '3'
          },
          {
            text: 'Mensual',
            value: '4'
          }
        ]
      }
    }
  },
  addItem: {
    0: {
      title: 'Atrás y agregar artículo',
      icon: 'fas fa-back'
    }
  },
  addModel: {
    0: {
      title: 'Regresar a Solicitar Boleto',
      icon: 'fas fa-undo'
    }
  }
}

export default tickets
