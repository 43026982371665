const admin = {
  sensors: {
    title: 'Wireless Gateways',
    itemFound: 'No items| 1 sensor found | {count} sensors found',
    placeholder: 'Search the list',
    searchLabel: 'Search',
    bleEditModal: {
      title: 'Wireless Gateway',
      labels: {
        0: 'Location*',
        1: 'Area',
        2: 'Gateway Type',
        3: 'Make-Model*',
        4: 'MAC address*',
        5: 'API Key',
        6: 'Min RSSI'
      }
    }
  },
  tags: {
    title: 'Wireless Tags',
    placeholder: 'Search the list',
    noMsg: 'No Results',
    retireTitle: 'Retire Wireless Tags',
    confirmDelete: 'Are you sure you want to retire the selected wireless tags?\n\nRetired tags linked to a tool will be unlinked.',
    restoreTitle: 'Restore Wireless Tags',
    confirmRestore: 'Restore {count} tag{plural}?',
    showRetiredTagToggleLabel: 'Show retired tags',
    bleTagForm: {
      titleAdd: 'Add Wireless Tag',
      titleEdit: 'Edit Wireless Tag',
      macInUse: 'is already used by an existing tag.',
      macDeleted: 'is already used.',
      alreadyLinked: 'is already linked to',
      relink: 'Link item to this tag',
      labels: {
        0: 'MAC Address',
        1: 'Gateway MAC address',
        2: 'Last Seen',
        3: 'Add Manually',
        4: 'Add Scanned Tag',
        5: 'Tag Model',
        6: 'Serial Number',
        7: 'Link To Item',
        8: 'Unlink',
        9: 'Scan or enter item',
        10: 'Deleted',
        11: 'GPS',
        12: 'Unique ID'
      },
      tooltips: {
        gpsEntitlement: 'Your contract is not entitled to this field'
      },
      validation: {
        required: '{field} is required',
        minMaxLength: '{field} must be at least {min} characters and at most {max} characters',
        maxLength: '{field} must be at most {max} characters',
        invalidMacAddress: 'Invalid {field} format. Please enter a 12 to 25 character {field} using only hexadecimal digits (0-9, A-F, a-f)'
      }
    },
    lineActions: [
      {
        title: 'Edit',
        actionName: 'editTag'
      },
      {
        title: 'Restore',
        actionName: 'restoreTag'
      },
      {
        title: 'Retire',
        actionName: 'retireTag'
      }
    ]
  },
  changeMyPassword: {
    title: 'Change Password',
    labels: {
      0: 'New Password:',
      1: 'Confirm Password:'
    }
  },
  dataLoader: {
    title: 'Data Loader',
    object: 'Object',
    DataLoaderActions: [
      'Export', 'Export All', 'Update', 'Create'
    ],
    DataLoaderObjects: [
      {
        key: 'Categories',
        value: 0
      },
      {
        key: 'Certifications',
        value: 1
      },
      {
        key: 'Classes',
        value: 2
      },
      {
        key: 'Cost Centers',
        value: 3
      },
      {
        key: 'Contractors',
        value: 4
      },
      {
        key: 'Customers',
        value: 5
      },
      {
        key: 'Departments',
        value: 6
      },
      {
        key: 'Employees',
        value: 7
      },
      {
        key: 'Employee Types',
        value: 8
      },
      {
        key: 'Locations',
        value: 9
      },
      {
        key: 'Manufacturers',
        value: 10
      },
      {
        key: 'Models',
        value: 11
      },
      {
        key: 'Service Classes',
        value: 12
      },
      {
        key: 'Shipping Methods',
        value: 13
      },
      {
        key: 'Status Codes',
        value: 14
      },
      {
        key: 'Stock Location Data',
        value: 15
      },
      {
        key: 'Unique Tools',
        value: 16
      },
      {
        key: 'Units of Measure',
        value: 17
      },
      {
        key: 'Vendors',
        value: 18
      },
      {
        key: 'Cost Codes',
        value: 19
      },
      {
        key: 'Quantity Tool Inventory',
        value: 20
      },
      {
        key: 'Material Inventory',
        value: 21
      },
      {
        key: 'Stock Locations',
        value: 22
      },
      {
        key: 'Service History',
        value: 23
      },
      {
        key: 'Quantity Tools',
        value: 24
      },
      {
        key: 'Work Orders',
        value: 25
      },
      {
        key: 'Work Order Charges',
        value: 26
      },
      {
        key: 'Misc Charges',
        value: 27
      },
      {
        key: 'Materials',
        value: 28
      },
      {
        key: 'Task Categories',
        value: 29
      },
      {
        key: 'Task Priorities',
        value: 30
      },
      {
        key: 'Task Statuses',
        value: 31
      },
      {
        key: 'Wireless Gateways',
        value: 32
      },
      {
        key: 'Wireless Tags',
        value: 33
      },
      {
        key: 'Equipments',
        value: 34
      },
      {
        key: 'Unique Tools',
        value: 35
      }
    ],
    labels: {
      update: 'Update',
      export: 'Export',
      exportAll: 'Export All (includes inactivate and deleted records)',
      create: 'Create',
      getTemplate: 'Get Template',
      getTagModels: 'Get Tag Model List',
      getGWModels: 'Get Gateway Model List',
      refresh: 'Refresh',
      feedback: 'Send Feedback'
    },
    confirmDlg: {
      title: 'Confirm Update',
      text: '{count} {Object} will be updated. Proceed?'
    }
  },
  customFields: {
    title: 'Custom Fields',
    placeholder: 'Search Custom Fields',
    editDlg: {
      title: {
        add: 'Create Custom Field',
        edit: 'Edit Custom Field'
      },
      fields: {
        name: 'Name',
        label: 'Label',
        desc: 'Description',
        recordType: 'Record Type'
      }
    }
  },
  companySettings: {
    title: 'Company Settings',
    logoTitle: 'Company Logo',
    logoDesc: 'Your company logo will be display at a 1:3 height to width ratio. For best results use an image that is at or close to 3 times as wide as it is tall.',
    toolLabels: {
      0: 'Tool & Equipment Aging Periods',
      1: 'Show Tool Browser warning when a tool hasn\'t been scanned, transferred, or counted in',
      2: 'Show Tool Browser warning when a tool hasn\'t been scanned, transferred, or counted in'
    },
    days: 'day | day | days',
    weeks: 'week | week | weeks',
    months: 'month | month | months',
    years: 'year | year | years',
    login: {
      0: 'Login',
      1: 'Minimum Password Length',
      2: 'Maximum Login Attempts',
      3: 'Lockout Period (minutes)',
      4: 'Lockout Window (minutes)',
      5: 'Show Remember Me on Login',
      6: 'Automatically Re-assign Assets Based on Wireless Scans',
      7: 'Auto Numbering',
      8: 'Enforce System Numbering',
      9: 'Duplicate protection',
      10: 'Recalculate percents in rate sheets when base rate changes'
    },
    duplicatedOptions: {
      0: 'Prevent',
      1: 'Warn'
    },
    twAppRequisitions: {
      0: 'ToolWatch App Requests',
      1: 'Default Pick Ticket Status'
    },
    twAppUOM: {
      0: 'Organization Defaults',
      1: 'Model Default Unit of Measure',
      2: 'Require Signature for App Field Mode Transfer',
      3: 'Enable Requisition Browser and Requisition Pick/Purchase Workflow',
      4: 'Display Replacement Cost in Mobile App'
    },
    pickStatusOptions: {
      0: 'Requested',
      1: 'Ready to Pick'
    },
    gpsSettings: {
      0: 'Wireless and GPS Settings',
      1: 'Automatically Re-assign Assets Based on Wireless Scans',
      2: 'Automatically Re-assign GPS Assets Based on Entering Geofence',
      3: 'After auto transfer block scans for: ___ hours',
      4: 'After manual transfer block scans for: ___ hours'
    },
    tooltips: {
      0: 'The maximum number of failed login attempts a user can make before being locked out. Setting this to zero allows unlimited attempts.',
      1: 'A user will be locked out for the lockout period if they exceed the number of login attempts within the Lockout Window.',
      2: 'The time a user has between their first failed attempt and the current login attempt before being locked out. A user that exceeds the maximum login attempts over this lockout duration will be locked out for the lockout period. A successful login resets this period.',
      3: 'For Requests submitted with the ToolWatch App, the resulting Pick Ticket will be created with the selected status. (Pick Tickets with a \'Ready to Pick\' status can no longer be modified.)',
      4: 'Custom Dashboard Elements are built using custom reports. Select the report you would like to build from then select the display type.',
      5: 'Set a time value (hours) to pause other auto transfers for processing after an auto transfer occurs.',
      6: 'Set a time value (hours) to pause other auto transfers for processing after a manually processed transfer occurs.'
    }
  },
  companyCalendar: {
    title: 'Company Default Calendar'
  },
  integrationSettings: {
    title: 'My Settings',
    placeholder: 'Settings search...'
  },
  configureUserRoles: {
    title: 'Roles Management',
    labels: {
      0: 'Role',
      1: 'Role Name:'
    },
    tabs: [
      {
        description: 'Tools & Equipment',
        moduleId: 2
      },
      {
        description: 'Materials & Consumables',
        moduleId: 3
      },
      {
        description: 'Pick Tickets & Transfers',
        moduleId: 4
      },
      {
        description: 'Service & Calibration',
        moduleId: 5
      },
      {
        description: 'Purchasing & Receiving',
        moduleId: 6
      },
      {
        description: 'Job Cost & Billing',
        moduleId: 7
      },
      {
        description: 'Reports & Graphs',
        moduleId: 8
      },
      {
        description: 'List Maintenance',
        moduleId: 9
      },
      {
        description: 'System',
        moduleId: 1
      }
    ],
    deleteWarning: {
      title: 'Delete role',
      message: 'This following users must be removed from this role before deletion: \n {users}'
    }
  },
  numbering: {
    title: 'Numbering',
    labels: {
      0: 'Number Type',
      1: 'Tool Number',
      2: 'Material Number',
      3: 'Kit Number',
      4: 'Last Used System Number',
      5: 'Last Used User Number',
      6: 'Current',
      7: 'History'
    },
    types: [
      {
        key: 'Barcode',
        value: 1
      },
      {
        key: 'Certification Number',
        value: 2
      },
      {
        key: 'Class Number',
        value: 3
      },
      {
        key: 'Contractor Number',
        value: 4
      },
      {
        key: 'Cost Center Number',
        value: 5
      },
      {
        key: 'Cost Code Number',
        value: 6
      },
      {
        key: 'Customer Number',
        value: 7
      },
      {
        key: 'Department Number',
        value: 9
      },
      {
        key: 'Employee Number',
        value: 10
      },
      {
        key: 'Employee Type Number',
        value: 11
      },
      {
        key: 'Tool Number',
        value: 12
      },
      {
        key: 'Location Number',
        value: 13
      },
      {
        key: 'Category Number',
        value: 14
      },
      {
        key: 'Model Number',
        value: 15
      },
      {
        key: 'Material Category Number',
        value: 16
      },
      {
        key: 'Material Number',
        value: 17
      },
      {
        key: 'Manufacturer Number',
        value: 18
      },
      {
        key: 'Pick Ticket Number',
        value: 19
      },
      {
        key: 'Service Request Number',
        value: 20
      },
      {
        key: 'Status Code Number',
        value: 21
      },
      {
        key: 'Template Number',
        value: 22
      },
      {
        key: 'Transfer Number',
        value: 23
      },
      {
        key: 'Units Number',
        value: 24
      },
      {
        key: 'Vendor Number',
        value: 25
      },
      {
        key: 'Work Order Number',
        value: 26
      },
      {
        key: 'Receiving Ticket Number',
        value: 27
      },
      {
        key: 'Purchase Order Number',
        value: 28
      },
      {
        key: 'Kit Number',
        value: 29
      },
      {
        key: 'Shipping Method Number',
        value: 30
      },
      {
        key: 'Service Class Number',
        value: 31
      },
      {
        key: 'Count Ticket Number',
        value: 32
      }
    ],
    noMsg: 'No matching records found',
    placeholder: 'Number Search...'
  },
  description: {
    title: 'Descriptions',
    placeholder: 'Search descriptions',
    labels: {
      0: 'All',
      1: 'Material',
      2: 'Calibration Task',
      3: 'Charge',
      4: 'Inspection Task',
      5: 'Service Request',
      6: 'Service Task',
      7: 'Template',
      8: 'Tool'
    },
    editDlg: {
      title: {
        0: 'Create Description',
        1: 'Edit Description',
        2: 'Delete Description'
      },
      labels: {
        0: 'Type',
        1: 'Select Type',
        2: 'Description'
      }
    },
    error: 'This description is in use and cannot be deleted.'
  },
  categories: {
    title: 'Categories',
    labels: {
      0: 'Tool Categories',
      1: 'Material Categories',
      2: 'Template Categories'
    },
    placeholder: 'Search Categories',
    editDlg: {
      title: {
        0: 'Create ',
        1: 'Edit ',
        2: 'Delete '
      },
      labels: {
        0: 'Category Number',
        1: 'Number',
        2: 'Description',
        3: 'Name'
      }
    },
    categoryTypeTitles: {
      0: 'Tool & Equipment Category',
      1: 'Material & Consumable Category',
      2: 'Template Category'
    }
  },
  tasksCategories: {
    title: 'Tasks Categories',
    placeholder: 'Search Tasks Categories',
    editDlg: {
      title: {
        0: 'Create Tasks Category',
        1: 'Edit Tasks Category',
        2: 'Delete Tasks Category'
      },
      labels: {
        0: 'Category Number',
        1: 'Description'
      }
    }
  },
  tasksPriorities: {
    title: 'Tasks Priorities',
    placeholder: 'Search Tasks Priorities',
    editDlg: {
      title: {
        0: 'Create Tasks Priority',
        1: 'Edit Tasks Priority',
        2: 'Delete Tasks Priority'
      },
      labels: {
        0: 'Priority Number',
        1: 'Description'
      }
    }
  },
  tasksStatuses: {
    title: 'Tasks Statuses',
    placeholder: 'Search Tasks Statuses',
    editDlg: {
      title: {
        0: 'Create Tasks Status',
        1: 'Edit Tasks Status',
        2: 'Delete Tasks Status'
      },
      labels: {
        0: 'Status Number',
        1: 'Description'
      }
    }
  },
  draft: {
    title: 'Unsaved Draft',
    body: 'There is an unsaved ticket in Draft status. Are you sure you want to cancel the existing draft, and create a new ticket?'
  },
  doNotTransfer: {
    title: 'Do not transfer',
    multipleItemMessage: 'There are multiple items with a status of \'Do Not Transfer\', Do you wish to override all items?',
    singleItemMessage: '{itemNumber} - {description} has a status of \'Do Not Transfer\'. Do you wish to override?'
  },
  editDashboard: {
    title: 'Add Element',
    saveChangs: 'Save Changes',
    builder: 'Builder',
    chartTitle: 'Title',
    report: 'Custom Report',
    displayAs: 'Display As',
    xAxis: 'X-axis',
    yAxis: 'Y-axis',
    series: 'Series',
    preview: 'Preview',
    xAxisValue: 'Select X-axis value',
    yAxisValue: 'Select Y-axis value',
    selectReport: 'Select a report',
    invalidData: 'The report you have selected is incompatible with this visual, please select another report or different visual',
    titleMessage: 'Enter Title',
    reportMessage: 'Select a Report',
    displayAsMessage: 'Select Display As',
    seriesMessage: 'Select a Series',
    selectMeasure: 'Measure',
    displayUnits: 'Display Units',
    lowRangeBreak: 'Low Range Break',
    highRangeBreak: 'High Range Break',
    checkMetricValue: 'Metric value should not be blank or zero.',
    customDashboardSuccess: 'Custom Dashboard has been saved successfully.',
    label: 'Label',
    labelValue: 'Select Label value',
    stacking: 'Stacking',
    customDashboardAssigned: 'Custom Dashboards has been assigned successfully.',
    columns: 'Columns',
    groupBy: 'Group By',
    sort: 'Sort',
    maxDisplayed: 'Max Displayed',
    maxDisplayedSetting: {
      0: '10',
      1: '20',
      2: '50',
      3: '100'
    },
    sorting: {
      0: 'Ascending',
      1: 'Descending'
    },
    groupByValue: 'Select Group By value',
    sortValue: 'Select Sort value',
    maxDisplayedValue: 'Select Max Displayed value',
    columnsMessage: 'Select Columns',
    barChartMessage: `The Y-axis and Series selected are not compatible
      with each other. Please select different combination.`,
    standardChartMessage: `The X-axis and Series selected are not compatible
      with each other. Please select different combination.`,
    pieChartMessage: `The Label and Series selected are not compatible
      with each other. Please select different combination.`,
    metricChartMessage: `The Measure and Display Units selected are not compatible
      with each other. Please select different combination.`,
    group: ' Group',
    filter: 'Filter'
  },
  customDashboardChartType: {
    lineChartId: '9aee7d0a-d423-436a-bcb6-952d999f852a',
    metricChartId: '60c1e068-40dd-4f58-a8eb-464a6d307cfb',
    barChartId: '7ecde5ca-6601-4eb6-91a5-287d3c8cd375',
    pieChartId: 'd368a578-f4db-4d65-9217-d060272391e8',
    columnChartId: '18ed5c73-db0d-4ff9-833e-2d175543dbf2',
    tableChartId: '6e6e513e-9cbf-4751-a959-b57c06783073'
  },
  customDashboardChartTypeName: {
    lineChart: 'LineChart',
    metricChart: 'MetricChart',
    barChart: 'BarChart',
    pieChart: 'PieChart',
    columnChart: 'ColumnChart',
    tableChart: 'TableChart'
  },
  customDashboardPages: {
    systemDashboard: '4598634b-16be-4419-9468-4f253eeb19a0',
    purchasingDashboard: '4aa7fa0e-758d-4794-9176-157260765cbf',
    serviceDashboard: 'd6f0e888-c2ad-4775-987b-8c32808b0643',
    pickTicketsTransfersDashboard: '79755f5c-f27f-4509-b917-ca9c42872ded'
  },
  certifications: {
    title: 'Certifications',
    placeholder: 'Search Certifications',
    editDlg: {
      title: {
        0: 'Create Certification',
        1: 'Edit Certification',
        2: 'Delete Certification',
        3: 'Clone Certification'
      },
      labels: {
        0: 'Code',
        1: 'Description',
        2: 'Renew Every',
        3: 'Notes'
      }
    }
  },
  classes: {
    title: 'Classes',
    placeholder: 'Search classes',
    editDlg: {
      title: {
        0: 'Create ',
        1: 'Edit ',
        2: 'Delete '
      },
      labels: {
        0: 'Class Number',
        1: 'Name'
      }
    }
  },
  contractors: {
    title: 'Contractors',
    placeholder: 'Search Contractors',
    editDlg: {
      title: {
        0: 'Create Contractor',
        1: 'Edit Contractor',
        2: 'Delete Contractor'
      },
      labels: {
        0: 'No.',
        1: 'Name',
        2: 'Account No.',
        3: 'Address 1',
        4: 'Address 2',
        5: 'City',
        6: 'State',
        7: 'Postal Code',
        8: 'Country',
        9: 'Contact',
        10: 'Email',
        11: 'Phone',
        12: 'Alt. Phone',
        13: 'Fax',
        14: 'Web URL',
        15: 'Notes'
      }
    }
  },
  customers: {
    title: 'Customers',
    placeholder: 'Search Customers',
    editDlg: {
      title: {
        0: 'Create Customer',
        1: 'Edit Customer',
        2: 'Delete Customer'
      },
      labels: {
        0: 'Customer Number',
        1: 'Name',
        2: 'Address 1',
        3: 'Address 2',
        4: 'City',
        5: 'State',
        6: 'Postal Code',
        7: 'Country',
        8: 'Contact',
        9: 'Phone',
        10: 'Alt. Phone',
        11: 'Notes'
      }
    }
  },
  departments: {
    title: 'Departments',
    placeholder: 'Search Departments',
    editDlg: {
      title: {
        0: 'Create Department',
        1: 'Edit Department',
        2: 'Delete Department'
      },
      labels: {
        0: 'Number',
        1: 'Description',
        2: 'Supervisor',
        3: 'Phone',
        4: 'Fax'
      }
    }
  },
  employees: {
    title: 'Employees',
    fuelTitle: 'Fuel Employees',

    placeholder: 'Search Employees',
    actionHeader: {
      0: {
        title: 'Import Employees',
        icon: 'fas fa-fas fa-file-export'
      }
    },
    labels: {
      0: 'All',
      1: 'Active',
      2: 'Inactive',
      3: 'Status'
    },
    editDlg: {
      title: {
        0: 'Create Employee',
        1: 'Edit Employee',
        2: 'Delete Employee'
      },
      labels: {
        0: 'First Name',
        1: 'Last Name',
        2: 'Employee ID',
        3: 'Bar Code'
      },
      tabs: {
        0: {
          title: 'General Info',
          labels: {
            0: 'Assigned Stock Location',
            1: 'Employee Address 1',
            2: 'Employee Address 2',
            3: 'City',
            4: 'State',
            5: 'Postal Code',
            6: 'Country',
            7: 'Phone1',
            8: 'Phone2',
            9: 'Email',
            10: 'Inactive',
            11: 'Inactive Date',
            12: 'Do Not Transfer',
            13: 'Reason',
            14: 'Decommission Reminder'
          }
        },
        1: {
          title: 'Employee Info',
          labels: {
            0: 'Departments',
            1: 'Supervisor',
            2: 'Employee Types',
            3: 'Hire Date',
            4: 'Termination Date',
            5: 'Employee',
            6: 'Contractor',
            7: 'Fuel Card User'
          }
        },
        2: {
          title: 'Billing Info',
          labels: {
            0: 'Cost Center',
            1: 'Cost Code'
          }
        },
        3: {
          title: 'Certifications'
        },
        4: {
          title: 'Notes',
          labels: {
            0: 'Notes',
            1: 'Notes: Maximum 4000 Characters ({count} too many)'
          }
        },
        5: {
          title: 'Record Info'
        },
        customFields: {
          title: 'Custom Fields'
        }
      }
    },
    manageTWUser: {
      title: 'User Account Administration',
      accessLevel: {
        0: 'User Access',
        1: 'Desktop',
        2: 'Mobile Warehouse',
        3: 'Mobile Field',
        4: 'Cloud'
      },
      formLabels: {
        password: {
          0: 'User Email',
          1: 'Existing Email',
          2: 'New Email',
          3: 'Email Address',
          4: 'Password',
          5: 'User Email & Password',
          6: 'Change Email Address',
          7: 'Change Password',
          8: 'Confirm Password',
          9: 'Password must be at least 8 characters',
          10: 'Password Fields must match'
        },
        roles: {
          0: 'Available Roles',
          1: 'One or more roles must be selected.'
        },
        deactivateWarning: {
          title: 'Deactivate User?',
          message: 'Are you sure you want to deactivate this account. The user will no longer have access to the ToolWatch system.'
        },
        confirmDialog: {
          confirmDlgTitle: 'User Account Administration',
          message: 'Changes to this employee record will be saved before editing the User Account.'
        }
      }
    }
  },
  employeeTypes: {
    title: 'Employee Types',
    placeholder: 'Search Employee Types',
    editDlg: {
      title: {
        0: 'Create Employee Type',
        1: 'Edit Employee Type',
        2: 'Delete Employee Type'
      },
      labels: {
        0: 'Code',
        1: 'Description'
      }
    }
  },
  locations: {
    title: 'Locations',
    placeholder: 'Search Locations',
    labels: {
      0: 'All',
      1: 'Active',
      2: 'Inactive',
      3: 'To be decommissioned',
      4: 'Status'
    },
    tabs: {
      0: 'Location Detail',
      1: 'Invoice Options',
      2: 'Custom Fields',
      3: 'Map'
    },
    editDlg: {
      title: {
        0: 'Create Location',
        1: 'Edit Location',
        2: 'Delete Location'
      },
      labels: {
        0: 'Parent Location',
        1: 'This is a stock location.',
        2: 'Location Number',
        3: 'Bar Code',
        4: 'Description',
        5: 'Address',
        6: 'City',
        7: 'Country',
        8: 'State',
        9: 'Postal Code',
        10: 'Location Phone',
        11: 'Fax',
        12: 'Supervisor',
        13: 'Cost Center',
        14: 'Cost Code',
        15: 'Notes',
        16: 'Inactive',
        17: 'Date',
        18: 'Cannot Transfer to this location',
        19: 'Reason',
        20: 'Decommission Reminder',
        21: 'Latitude, Longitude'
      },
      invoiceOptions: {
        0: 'Business Unit',
        1: 'Terms',
        2: 'Tax %',
        3: 'Special Instructions',
        4: 'Dunning Message',
        5: 'Remittance Address',
        6: 'Remittance address same as location',
        7: 'Name',
        8: 'Address',
        9: 'Address2',
        10: 'City',
        11: 'State',
        12: 'Postal Code',
        13: 'Country'
      },
      map: {
        0: 'Enable Geofence',
        1: 'Geofence Radius',
        2: 'Unit of Measure'
      },
      cannotChangeParent: 'You cannot change the position of {description} because it is the top of your organization tree.',
      latLongValidation: 'Latitude and Longitude must contain two positive or negative numeric values seperated by a comma',
      geofenceValidation: 'Minimum Radius can not be less than {0} {1}'
    },
    dialogLabels: {
      saveLocationInactive: 'Cannot make location inactive',
      inactiveMessage: 'This location can not be made inactive because {0} locations are assigned to it. Please delete or make those locations inactive first.'
    },
    geoFenceUoms: [
      {
        key: 'meters',
        description: 'Meters',
        conversionToMeters: 1,
        minValue: 50
      },
      {
        key: 'feet',
        description: 'Feet',
        conversionToMeters: 0.3048,
        minValue: 150
      },
      {
        key: 'yards',
        description: 'Yards',
        conversionToMeters: 0.9144,
        minValue: 50
      },
      {
        key: 'miles',
        description: 'Miles',
        conversionToMeters: 1609.34,
        minValue: 0.03
      }
    ]
  },
  manufacturers: {
    title: 'Manufacturers',
    placeholder: 'Search Manufacturers',
    editDlg: {
      title: {
        0: 'Create Manufacturer',
        1: 'Edit Manufacturer',
        2: 'Delete Manufacturer'
      },
      labels: {
        0: 'Number',
        1: 'Name',
        2: 'Phone',
        3: 'Fax',
        4: 'UPC Prefix',
        5: 'Web Address'
      }
    }
  },
  numberBlocks: {
    title: 'Stockpoint Number Reserves',
    placeholder: 'Search Number Blocks',
    editDlg: {
      title: {
        0: 'Create Stockpoint Number Reserves',
        1: 'Edit Stockpoint Number Reserves',
        2: 'Delete Stockpoint Number Reserves'
      },
      labels: {
        0: 'Number Type',
        1: 'Block Size',
        2: 'Current Number'
      }
    }
  },
  dataMergeUtility: {
    title: 'Data Merger'
  },
  shippingMethods: {
    title: 'Shipping Methods',
    placeholder: 'Search Shipping Methods',
    editDlg: {
      title: {
        0: 'Create Shipping Method',
        1: 'Edit Shipping Method',
        2: 'Delete Shipping Method'
      },
      labels: {
        0: 'Number',
        1: 'Name'
      }
    }
  },
  statusCodes: {
    title: 'Status Codes',
    placeholder: 'Search Status Codes',
    editDlg: {
      title: {
        0: 'Create Status Code',
        1: 'Edit Status Code',
        2: 'Delete Status Code'
      },
      labels: {
        0: 'Code',
        1: 'Description',
        2: 'Can\'t be transferred or moved',
        3: 'Needs service or repair',
        4: 'Destroyed beyond repair',
        5: 'Will be retired and sent to Lost & Found'
      }
    }
  },
  unitsOfMeasures: {
    title: 'Units Of Measure',
    placeholder: 'Search Units of Measure',
    editDlg: {
      title: {
        0: 'Create Unit Of Measure',
        1: 'Edit Unit Of Measure',
        2: 'Delete Unit Of Measure'
      },
      labels: {
        0: 'Unit',
        1: 'Description'
      }
    },
    notDeleteUOMMsg: 'This is a default unit of measure for your organization. Default must be changed before deleting this Unit of Measure.',
    notEditUOMMsg: 'This is a default unit of measure for your organization. Default must be changed before editing this Unit of Measure.'
  },
  vendors: {
    title: 'Vendors',
    placeholder: 'Search Vendors',
    editDlg: {
      title: {
        0: 'Create Vendor',
        1: 'Edit Vendor',
        2: 'Delete Vendor'
      },
      labels: {
        0: 'Number',
        1: 'Name',
        2: 'Inactive'
      },
      tabs: {
        0: {
          title: 'Vendor Info',
          labels: {
            0: 'Address',
            1: 'City',
            2: 'State',
            3: 'Postal Code',
            4: 'Country',
            5: 'Web URL',
            6: 'Notes'
          }
        },
        1: {
          title: 'Contact Info',
          labels: {
            0: 'Contact',
            1: 'Email',
            2: 'Phone',
            3: 'Mobile',
            4: 'FAX'
          }
        },
        2: {
          title: 'Account Info',
          labels: {
            0: 'Account No.',
            1: 'Shipping Method',
            2: 'When printing the Discrepancy report for this vendor, allow this price variance before adding items to the report.',
            3: 'Discrepancy Variance'
          }
        },
        3: {
          title: 'Custom Field',
          labels: {
          }
        }
      }
    }
  },
  costCenters: {
    title: 'Cost Centers',
    placeholder: 'Search Cost Centers',
    editDlg: {
      title: {
        0: 'Create Cost Center',
        1: 'Edit Cost Center',
        2: 'Delete Cost Center'
      },
      labels: {
        0: 'No.',
        1: 'Description'
      },
      tabs: {
        0: {
          title: 'General Info',
          labels: {
            0: 'Manager',
            1: 'Customer',
            2: 'Start Date',
            3: 'End Date',
            4: 'Code Group',
            5: 'Enable Global Cost Codes',
            6: 'Inactive'
          }
        },
        1: {
          title: 'Project Rates',
          labels: {
            0: 'Tools & Equipment Rate Sheet',
            1: 'Materials & Consumables Rate Sheet'
          }
        },
        2: {
          title: 'Project Billing Rules',
          labels: {
            0: 'User this order when issuing credits for quantity tools returned:',
            1: 'LOFI (Last Out, First In)',
            2: 'FOFI (First Out, First In)'
          }
        },
        3: {
          title: 'Project Calendar',
          labels: {
            0: 'Tools returned before',
            1: 'are not billed for that day.',
            2: 'Tools assigned after'
          }
        },
        4: {
          title: 'Notes',
          labels: {
            0: 'Notes'
          }
        },
        customFields: {
          title: 'Custom Fields',
          labels: {
          }
        }
      }
    }
  },
  costCodes: {
    title: 'Cost Codes',
    placeholder: 'Search Cost Codes',
    editDlg: {
      title: {
        0: 'Create Cost Code',
        1: 'Edit Cost Code',
        2: 'Delete Cost Code'
      },
      labels: {
        0: 'Code',
        1: 'Description',
        2: 'Cost Center',
        3: 'Code Group',
        4: 'Inactive'
      }
    }
  },
  serviceClasses: {
    title: 'Service Classes',
    placeholder: 'Search Service Classes',
    editDlg: {
      title: {
        0: 'Create Service Class',
        1: 'Edit Service Class',
        2: 'Delete Service Class'
      },
      labels: {
        0: 'Number',
        1: 'Name'
      }
    }
  },
  taggedForm: {
    title: {
      0: 'Renewal',
      1: 'Record Information'
    },
    labels: {
      0: 'Created',
      1: 'By',
      2: 'Last Modified'
    }
  },
  pagingLabel: {
    0: 'Showing',
    1: 'to',
    2: 'of',
    3: 'rows'
  },
  userPreferences: {
    title: 'User Preferences',
    notificationsSection: {
      title: 'Notifications',
      verifiedPhoneNumber: 'Verified phone number for SMS notifications:',
      noVerifiedPhoneNumber: 'No verified phone number set',
      notifyMeWhen: 'Notify me when...',
      byEmail: 'By email...',
      bySms: 'By SMS...',
      pickTicketRequested: 'Pick Tickets are Requested at a Location I Supervise',
      pickTicketReadyToPick: 'Pick Tickets are Ready to Pick at a Location that I Supervise',
      pickTicketCompleted: 'Pick Tickets Requested by me are Completed',
      assetsTransferredToLocation: 'Assets are Transferred to a Location that I Supervise',
      assetsTransferredToMe: 'Assets are Transferred to me',
      workOrderCreated: 'Work Orders Created at an Assignment I Supervise',
      serviceRequestCreated: 'Service Request Created at an Assignment I Supervise',
      assignedResponsibleParty: 'I am Assigned as the Responsible Party for a Tool',
      clearedResponsibleParty: 'I am Cleared as the Responsible Party for a Tool',
      assetsReinstated: 'Assets are Reinstated at a Location that I Supervise',
      employeeSuperviseCertification: 'Employees I supervise have a certification that has expired',
      toolsDueForReturnToday: 'Assets are due for return today from a location I supervise',
      toolsPastDueForReturn: 'Assets due for return in the past 30 days from a location I supervise are past due',
      completedCalcs: 'Job Cost and Billing calculations I have started and completed',
      locationTitle: 'Location Specific Notifications',
      locationNotification: 'User does not have to be set as Location Supervisor to receive Notification',
      approvalNotification: 'I have open approvals',
      outOfBondNotification: 'Assets with GPS are out of bounds at a location that I supervise',
      inBondsNotification: 'Items with GPS have arrived at a location I supervise',
      retiredToolNotification: 'Retired Tool has been scanned at a location I supervise',
      requisitionBrowserNotification: 'Requisition Submitted for a Location I supervise'
    },
    locationNotificationSection: {
      pickTicketRequested: 'Pick Tickets Requested',
      pickTicketReadyToPick: 'Pick Tickets Ready',
      pickTicketCompleted: 'Pick Tickets Completed',
      assetsTransferredToLocation: 'Assets Transferred to Location',
      workOrderCreated: 'Work Order Created',
      assetsReinstated: 'Assets Reinstated',
      toolsDueForReturnToday: 'Assets at location are due today',
      toolsPastDueForReturn: 'Assets at location are past due',
      locationHeader: 'Location',
      blankWrngMsg: '*Please select a location to set up location specific notifications.',
      userLocationPreferencesbtn: 'User Notifications',
      locationPreferencesbtn: 'Location Notifications',
      outOfBondNotification: 'Assets with GPS are out of bounds at a location',
      inBondsNotification: 'Items with GPS have arrived at a location',
      serviceRequestCreated: 'Service Request Created',
      retiredToolNotification: 'Retired Tool has been scanned',
      requisitionBrowserNotification: 'Requisition Submitted'

    },
    expressCheckInSection: {
      title: 'Express Check-In',
      duringExpressCheckIn: 'During Express Check-In...',
      presentTransferItemsAs: 'All transfer items should initially be presented as',
      checked: 'Checked',
      unchecked: 'Unchecked'
    },
    smsPopups: {
      sendVerificationCodeMessage: 'Please enter your phone number to receive text (SMS) notifications. We will send you a one-time verification code. Message and data rates may apply.',
      sendVerificationCodeButton: 'Send Verification Code',
      countryCode: 'Country Code',
      phoneNumber: 'Phone Number',
      enterPhoneNumber: 'Enter Phone Number',
      enterVerificationCode: 'Enter Verification Code',
      verifyCode: 'Verify Code',
      verificationCode: 'Verification Code',
      weSentATextMessage: 'We just sent a text message to',
      withAOneTimeCode: 'with a one-time verification code. Please enter that code here to verify your phone number.'
    },
    toolTip: {
      notificationsSection: {
        pickTicketRequested: 'Notification triggered when there are new Pick Tickets and in a Ready to Pick State at a Location that you are set as the Supervisor',
        pickTicketReadyToPick: 'Notification triggered when there are new Pick Tickets and submitted in a Requested State at a Location that you are set as the Supervisor',
        pickTicketCompleted: 'Notification triggered when Requisitions I have submitted have been completed',
        assetsTransferredToLocation: 'Notification triggered when new assets have been transferred to you',
        assetsTransferredToMe: 'Notification triggered when new assets have been transferred to a Location that you are set as the Supervisor',
        workOrderCreated: 'Notification triggered when new Work Orders have been created at a location that you are set as the Supervisor',
        serviceRequestCreated: 'Notification triggered when new Service Requests have been created at a location that you are set as the Supervisor',
        assignedResponsibleParty: 'Notification triggered when you are set as the Responsible Party for an item',
        clearedResponsibleParty: 'Notification triggered when you are no longer set as the Responsible Party for an item',
        assetsReinstated: 'Notification triggered when assets that were Retired are Reinstated at a Location that you are set as the Supervisor',
        employeeSuperviseCertification: 'Notification triggered when employees that you are set as the Supervisor for have certifications that have expired',
        toolsDueForReturnToday: 'Notification triggered when items have a return date set as today at a Location that you are set as the Supervisor',
        toolsPastDueForReturn: 'Notification triggered when items have a return date set in the past 30 days at a Location that you are set as the Supervisor',
        completedCalcs: 'Notification triggered when calculations are ready to view',
        locationTitle: 'Location Specific Notifications',
        locationNotification: 'User does not have to be set as Location Supervisor to receive Notification',
        approvalNotification: '',
        outOfBondNotification: 'Notification triggered when items with GPS associated report in outside the geofence boundaries of the assignment location for the item',
        inBondsNotification: 'Notification triggered when items with GPS associated report in inside the geofence boundaries of a location for the first time',
        retiredToolNotification: 'Notification triggered when items with BLE are in a retired state and picked up by a wireless scan at a location that you are set as the Supervisor'
      },

      locationNotificationSection: {
        pickTicketRequested: 'Notification triggered when there are new Pick Tickets and submitted in a Requested State at the Location that you have selected from the dropdown above',
        pickTicketReadyToPick: 'Notification triggered when there are new Pick Tickets and in a Ready to Pick State at the Location that you have selected from the dropdown above',
        pickTicketCompleted: 'Notification triggered when there are new Pick Tickets and in a Completed State at the Location that you have selected from the dropdown above',
        assetsTransferredToLocation: 'Notification triggered when there are assets transferred to the Location that you have selected from the dropdown above',
        workOrderCreated: 'Notification triggered when there are Work Orders created the Location that you have selected from the dropdown above',
        assetsReinstated: 'Notification triggered when there are assets reinstated from a Retired state at the Location that you have selected from the dropdown above',
        toolsDueForReturnToday: 'Notification triggered when there are items due for return today at the Location that you have selected from the dropdown above',
        toolsPastDueForReturn: 'Notification triggered when there are items past due for return at the Location that you have selected from the dropdown above',
        outOfBondNotification: 'Notification triggered when items with GPS associated report in outside the geofence boundaries of the assignment location for the item if it is the location in the dropdown above',
        inBondsNotification: 'Notification triggered when items with GPS associated report in inside the geofence boundaries of the location in the dropdown above for the first time',
        retiredToolNotification: 'Notification triggered when items with BLE are in a retired state and picked up by a wireless scan at the location in the dropdown above',
        serviceRequestCreated: 'Notification triggered when there are Service Requests created the Location that you have selected from the dropdown above'
      }
    },
    feedback: 'Send Feedback'
  }
}

export default admin
