<template>
  <div id="page-wrapper" :class="isResultPage ? 'sidebar-content' : ''">
    <div class="wrapper wrapper-content">
      <div class="row" v-if="!isDashboard">
        <div class="tw-search-toggle switch-size pl-2">
          <ToggleSwitch
            :isActive="isShowOnlyTracked"
            :activeColor="activeColor"
            @onActiveChanged="showOnlyTracked">
          </ToggleSwitch>
          <p>{{ isShowOnlyTracked ? $t('catalog.switcher') : $t('catalog.allProducts') }}</p>
        </div>
        <action-header
          style="margin: 15px 0 0 15px;"
          v-if="isResultPage || isHomePage"
          ref="actionHeader"
          :actionHeaders="actionHeaders"
          @onSelectAction="selectAction"
        ></action-header>
      </div>
      <CatalogNavbar
        v-if="userPermissions && !isDetailPage"
        :SearchText="searchText"
        :QueryText="queryText"
        :isShowOnlyTracked="isShowOnlyTracked"
        :isDetail="isDetailPage || isHomePage"
        :searchHappened="searchHappened"
        :totalEntries="totalResultsCount"
        @getStartingManufacturersAndCategories="getStartingManufacturersAndCategories"
      />
      <!-- <div class="row" v-if="!isDetail && !isDashboard">
        <div class="col">
          <div v-if="searchHappened" class="form-text">
            <span v-if="searchHelpTextPrefix">{{searchHelpTextPrefix}}</span>

            <span v-if="searchText" class="orange-text">
              "{{searchResults.SearchTermUsed ? searchResults.SearchTermUsed : searchText}}"
            </span>

            <span v-if="this.searchCategory !== ''">
              {{$t('message.intervention.1')}} <span class="orange-text">"{{searchCategory}}"</span>
            </span>

            <span v-if="this.searchManufacturer !== ''">
              {{$t('message.intervention.2')}} <span class="orange-text">"{{searchManufacturer}}"</span>
            </span>

            <span v-if="searchSwitched"> {{$t('message.intervention.3')}}</span>
          </div>
        </div>
      </div> -->
      <router-view
        :searchResults="mergedResults"
        :totalEntries="totalResultsCount"
        :defaultPageSize="pageSize"
        :defaultPageNumber="pageNumber"
        :canAddModel="canAddModel"
        :searchHappened="searchHappened"
        :hasMergedResults="hasMergedResults"
        :dewaltUsn="searchResults && searchResults.DewaltUsnModel"
        :isShowOnlyTracked="isShowOnlyTracked"
        @detailsClick="onCatalogDetailsClick"
        @addItemClick="onCatalogRecordClick"
        @createNewItem="onCreateNewItem"
        @pageChange="setPage"
        @pageSizeChange="setPageSize"
        @searchToolWatchCatalog="searchToolWatchCatalog"
      ></router-view>
    </div>
    <div class="sidebar-panel" v-if="isResultPage">
      <div class="tw-category-selector">
        <div class="tabs-container">
          <div class="panel-body">
            <div v-if="isShowOnlyTracked">
              <ReqAssetTypeSelector
              :assetKind="assetKind"
              @assetChange="setAssetKind"
            />
            </div>
            <div class="terms-holder">
              <div v-if="isShowOnlyTracked" class="single-term">
                <RequisitionSearchFilterList
                  ref="categoryFilter"
                  :isCatalogPage="isCatalogPage"
                  :filterTitle="$t('catalog.category')"
                  :searchResults="listViewResults"
                  :categorySeeMoreLength="categorySeeMoreLength"
                  :manufacturerSeeMoreLength="manufacturerSeeMoreLength"
                  @searchCategory="setCategory"
                />
              <div class="single-term">
                <RequisitionSearchFilterList
                  ref="brandFilter"
                  :isCatalogPage="isCatalogPage"
                  :filterTitle="$t('catalog.brand')"
                  :searchResults="listViewResults"
                  :categorySeeMoreLength="categorySeeMoreLength"
                  :manufacturerSeeMoreLength="manufacturerSeeMoreLength"
                  @searchManufacturer="setManufacturer"
                />
              </div>
              </div>
              <div v-if="!isShowOnlyTracked" class="single-term">
                <RequisitionSearchFilterList
                  ref="brandFilter"
                  :isCatalogPage="isCatalogPage"
                  :filterTitle="$t('catalog.brand')"
                  :searchResults="searchResults"
                  :categorySeeMoreLength="categorySeeMoreLength"
                  :manufacturerSeeMoreLength="manufacturerSeeMoreLength"
                  @searchManufacturer="setManufacturer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isDashboard">
      <ReqMoreCategory :categories="$t('catalog.category')" :searchResults="listViewResults" @searchCategory="setCategory"/>
      <ReqMoreCategory :categories="$t('catalog.brand')"  :searchResults="listViewResults" @searchManufacturer="setManufacturer"/>
      <CatalogImportModel
        ref="catalogImportModel"
        v-if="catalogRecord && isImporting && catalogRecord.newItemFromScratch || catalogRecord.MasterId || catalogRecord.CatalogModelId"
        :doCreateUniqueTool="catalogRecord.newItemFromScratch === true"
        :key="catalogRecord.MasterId + catalogRecord.CatalogModelId + '_' + addAttempt"
        :catalogRecord="catalogRecord"
        @importSuccess="onImportSuccess"
        :dewaltUsn="searchResults && searchResults.DewaltUsnModel"
        @newItemFromScratch="onNewItemFromScratch"
        @hideModal="hideCatalogImportDlg"
      />
    </template>
  </div>
</template>
<script>
import ReqAssetTypeSelector from '../../components/shared/ReqAssetTypeSelector.vue'
import RequisitionSearchFilterList from '../../components/requisitions/RequisitionSearchFilterList.vue'
import ReqMoreCategory from '../../components/requisitions/ReqMoreCategory.vue'
import CatalogNavbar from '../../catalogs/CatalogNavbar.vue'
import CatalogImportModel from '../../catalogs/CatalogImportModel.vue'
import axios from 'axios'
import $ from 'jquery'
import ToggleSwitch from '../shared/ToggleSwitch.vue'
import { bus } from '../../main'
import ActionHeader from '../shared/ActionHeader.vue'
import { errorHandler } from '../../helpers/utils'

export default {
  name: 'CatalogLayout',
  components: {
    RequisitionSearchFilterList,
    ReqMoreCategory,
    CatalogNavbar,
    CatalogImportModel,
    ReqAssetTypeSelector,
    ToggleSwitch,
    ActionHeader
  },
  data () {
    return {
      searchResults: null,
      searching: false,
      pageNumber: 1,
      pageSize: 10,
      eSearchCatalog: { company: 1, tw: 2 },
      eAssetKind: ['A', 'T', 'M'],
      assetKind: '',
      categorySeeMoreLength: 12,
      isCategoryExpanded: false,
      checkedCategories: [],
      manufacturerSeeMoreLength: 12,
      isManufacturerExpanded: false,
      queryText: '',
      orgName: null,
      orgLogoUrl: '',
      isLogo: false,
      catalogRecord: {},
      addAttempt: 1, // considered
      isImporting: false,
      isProcessing: false
    }
  },
  props: {
    isCatalogPage: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  async created () {
    if (!this.isDashboard) {
      this.initSearchKey()
      try {
        const params = JSON.stringify({
          width: '\'90\'',
          height: '\'30\''
        })
        const data = await Promise.all([
          axios.post('/api/core/OrgsService/FindOrgById', {}),
          axios.post('/api/core/OrgSettingsService/GetCompanyLogoUri', params)
        ])
        this.orgName = data[0]?.data.Body?.Name.trim()
        this.orgLogoUrl = data[1]?.data?.Body
        if (this.orgLogoUrl === null || this.orgLogoUrl === '') {
          this.isLogo = false
        } else {
          this.isLogo = true
        }
        if (!this.isDetailPage && !this.isHomePage) {
          await this.setFilter()
          this.parseUrl()
        }
      } catch (error) {}
    }
    bus.$on('reload', (data) => {
      if (data) {
        this.parseUrl()
      }
    })
  },
  methods: {
    parseUrl () {
      const parsedPageNumber = parseInt(this.$route.query.pageNumber)
      this.pageNumber = Number.isNaN(parsedPageNumber) ? 1 : parsedPageNumber
      // }
      if (this.$route.query.q) {
        this.queryText = this.$route.query.q
      }
      // this.$store.commit('setSearchCriteria', { q: parsedUrl.searchParams.get('q') })
      if (this.$route.query.assetKind) {
        const parsedAssetKind = this.$route.query.assetKind
        if (this.eAssetKind.includes(parsedAssetKind)) {
          this.assetKind = parsedAssetKind
        } else {
          this.assetKind = 'T'
        }
      }
      if (this.isShowOnlyTracked) {
        if (!this.searchText && this.searchManufacturerList && this.searchCategories) {
          this.getStartingManufacturersAndCategories()
        } else {
          this.search()
        }
      } else {
        this.search()
      }
    },
    async doSearch () {
      this.$store.commit('setLoading', true)
      this.makeApiSearchCall(this.globalStockpointId)
    },
    search: async function () {
      this.searching = true
      await this.doSearch()
    },
    async makeApiSearchCall (currentStockpointId) {
      try {
        if (!this.isGrid) {
          this.pageSize = -1
        }
        const params = JSON.stringify({
          catalogSearchRequestDto: JSON.stringify({
            Query: this.queryText,
            PageNumber: this.pageNumber,
            PageSize: this.pageSize,
            Manufacturer: this.searchManufacturerList,
            Category: this.searchCategories,
            Catalog: this.catalogSearchType,
            CurrentStockpointId: currentStockpointId,
            AssetKind: this.assetKind,
            isModelIdSearch: true
          })
        })
        const { data } = await axios.post('/api/core/CatalogSearch/SearchCatalog', params)
        this.searching = false
        this.$store.commit('setLoading', false)
        this.searchResults = data?.Body
      } catch (error) {
        this.searching = false
        this.$store.commit('setLoading', false)
        errorHandler(this.$toast, error.response.statusText, error)
      }
    },
    getStartingManufacturersAndCategories: async function () {
      this.$store.commit('setLoading', true)
      this.makeApiStartingManufacturersAndCategoriesCall(this.globalStockpointId)
    },
    async makeApiStartingManufacturersAndCategoriesCall (currentStockpointId) {
      this.searching = true
      try {
        const page = this.pageSize
        if (!this.isGrid) {
          this.pageSize = -1
        }
        const params = JSON.stringify({
          catalogSearchRequestDto: JSON.stringify({
            Query: this.searchText !== undefined ? this.searchText : this.queryText,
            PageNumber: this.pageNumber,
            PageSize: this.pageSize,
            Manufacturer: this.searchManufacturerList,
            Category: this.searchCategories,
            Catalog: this.catalogSearchType,
            CurrentStockpointId: currentStockpointId,
            AssetKind: this.assetKind
          })
        })
        this.pageSize = page
        const { data } = await axios.post('/api/core/CatalogSearch/SearchCatalog', params)
        this.searching = false
        this.$store.commit('setLoading', false)
        this.searchResults = data?.Body
      } catch (error) {
        this.searching = false
        this.$store.commit('setLoading', false)
        errorHandler(this.$toast, error.response.statusText, error)
      }
    },
    async setFilter () {
      this.loading = true
      const paramSearch = JSON.stringify({
        catalogSearchRequestDto: JSON.stringify({
          Catalog: 1,
          AssetKind: this.assetKind
        })
      })
      try {
        const { data } = await axios.post('/api/core/CatalogSearch/SearchCatalog', paramSearch)
        this.$store.commit('setCatalogResults', data?.Body)
      } catch (error) {
        errorHandler(this.$toast, error.response.statusText, error)
      } finally {
        this.loading = false
      }
    },
    async setPage (pageNumber) {
      this.pageNumber = pageNumber
      try {
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            pageNumber: pageNumber
          }
        })
        // this.search()
      } catch (error) {}
    },
    async setPageSize (pageSize) {
      this.pageSize = parseInt(pageSize)
      this.pageNumber = 1
      try {
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            pageNumber: 1,
            pageSize: this.pageSize
          }
        })
        // this.search()
      } catch (error) {}
    },
    async setManufacturer (manufacturer) {
      const searchManufacturer = [...new Set(manufacturer)]
      this.pageNumber = 1
      try {
        const obj = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            pageNumber: 1,
            manufacturer: searchManufacturer ? searchManufacturer.join('|') : ''
          }
        }
        await this.$router.push(obj)
        localStorage.setItem('catalog-reset-url', false)
        localStorage.setItem('catalog-latest-url', JSON.stringify(obj))
        this.$store.commit('', searchManufacturer)
        // this.search()
      } catch (error) {}
    },
    async setCategory (category, isAllChecked) {
      const searchCategories = [...new Set(category)]
      this.pageNumber = 1
      localStorage.setItem('catalog-reset-url', false)
      if (isAllChecked == false) {
        const obj = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            pageNumber: 1,
            category: 'none'
          }
        }
        await this.$router.push(obj)
        localStorage.setItem('catalog-latest-url', JSON.stringify(obj))
      } else {
        try {
          const obj = {
            path: this.$route.path,
            query: {
              ...this.$route.query,
              q: this.$route.query.q ? this.$route.query.q : '',
              pageNumber: 1,
              category: searchCategories ? searchCategories.join('|') : ''
            }
          }
          await this.$router.push(obj)
          localStorage.setItem('catalog-latest-url', JSON.stringify(obj))
          this.$store.commit('setSearchCategoryList', searchCategories)
        // this.search()
        } catch (error) {}
      }
    },
    async setAssetKind (kind) {
      if (this.assetKind == kind) return
      this.pageNumber = 1
      this.$store.commit('setSearchCategoryList', [])
      this.$store.commit('setSearchManufacturerList', [])
      try {
        const obj = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            pageNumber: 1,
            assetKind: kind,
            category: 'none',
            manufacturer: ''
          }
        }
        await this.$router.push(obj)
        localStorage.setItem('catalog-latest-url', JSON.stringify(obj))
        localStorage.setItem('catalog-reset-url', false)
        this.setFilter()
      } catch (error) {}
    },
    async searchToolWatchCatalog () {
      this.$store.commit('setIsShowOnlyTracked', false)
    },
    currentRecordsText () {
      if (this.searchResults.TotalCount <= this.searchResults.PageSize) {
        return ' '
      }
      let endRecord = this.searchResults.CurrentPage * this.searchResults.PageSize
      const startRecord = endRecord - this.searchResults.PageSize + 1

      if (endRecord > this.searchResults.TotalCount) {
        endRecord = this.searchResults.TotalCount
      }

      return startRecord + '-' + endRecord + ' of '
    },
    async onOrgNameChange (orgName) {
      this.orgName = orgName
      try {
        await this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            q: this.$route.query.q ? this.$route.query.q : '',
            orgName: orgName
          }
        })
        // this.search()
      } catch (error) {}
    },
    onCatalogRecordClick (catalogRecord) {
      this.addAttempt++
      this.catalogRecord = catalogRecord
      try {
        this.isImporting = true
        this.$nextTick(function () {
          this.$refs.catalogImportModel.getNewItemData()
          $('#catalogImportModal').modal('show')
        })
      } catch (error) {
        this.isImporting = false
      }
    },
    async onCatalogDetailsClick (catalogRecord) {
      try {
        if (catalogRecord.MasterId) {
          const obj = {
            path: `/catalog/detail/?masterId=${encodeURIComponent(catalogRecord.MasterId)}`,
            query: { ...this.$route.query }
          }
          await this.$router.push(obj)
        } else if (catalogRecord.CatalogModelId) {
          const obj = {
            path: `/catalog/detail/?catalogModelId=${encodeURIComponent(catalogRecord.CatalogModelId)}`,
            query: { ...this.$route.query }
          }
          await this.$router.push(obj)
        }
      } catch (error) {}
    },
    initSearchKey () {
      this.$store.commit('setSearchCategoryList', this.$route.query.category ? this.$route.query.category.split('|') : [])
      this.$store.commit('setSearchManufacturerList', this.$route.query.manufacturer ? this.$route.query.manufacturer.split('|') : [])
      this.pageNumber = this.$route.query.pageNumber ? this.$route.query.pageNumber : 1
      this.queryText = this.$route.query.q ? this.$route.query.q : ''
      this.$store.commit('setSearchCriteria', {
        q: this.queryText
      })
    },
    onImportSuccess (successToastMessage, closeModal) {
      this.catalogRecord.IsTracked = true
      if (closeModal) {
        this.hideCatalogImportDlg()
      }
      if (successToastMessage) {
        this.$toast.success(successToastMessage, {
          position: 'top'
        })
      }
    },
    hideCatalogImportDlg () {
      $('#catalogImportModal').modal('hide')
      this.isImporting = false
    },
    onNewItemFromScratch (catalogRecord) {
      this.onCatalogDetailsClick(catalogRecord)
    },
    onCreateNewItem () {
      this.addAttempt++
      this.catalogRecord = { newItemFromScratch: true, ItemType: this.assetKind }
      try {
        this.isImporting = true
        this.$nextTick(function () {
          this.$refs.catalogImportModel.getNewItemData()
          $('#catalogImportModal').modal('show')
        })
      } catch (error) {
        this.isImporting = false
      }
    },
    errorHandler (error) {
      if (error.response) {
        this.searching = false
        errorHandler(this.$toast, error.response.statusText, error)
      }
    },
    showOnlyTracked (flag) {
      this.$store.commit('setIsShowOnlyTracked', flag)
      localStorage.setItem('catalog-isShowOnlyTracked', flag)
    },
    selectAction (index) {
      switch (Number(index)) {
        case 0:
          this.returnToPrevious()
          break
        case 1:
          this.onCreateNewItem()
          break
      }
    },
    returnToPrevious () {
      localStorage.setItem('catalog-reset-url', true)
      this.$router.push({
        path: '/catalog'
      })
    }
  },
  computed: {
    searchHappened () {
      return (this.searchText !== '' || this.searchManufacturerList !== '' || this.searchCategories) &&
                this.searchResults && !this.searching
    },
    searchHelpTextPrefix () {
      const searchSwitched = this.searchResults.SearchTermUsed && this.searchResults.SearchTermUsed !== this.searchText

      return '' + (searchSwitched ? '0 results found for ' + this.searchText + '. Showing ' : '') +
                (this.currentRecordsText()) +
                this.$tc('message.results', this.totalResultsCount) +
                (this.searchText ? ` ${this.$t('message.intervention.0')} ` : '')
    },
    searchSwitched () {
      return this.searchResults.SearchTermUsed && this.searchResults.SearchTermUsed !== this.searchText
    },
    mergedResults () {
      if (this.searchResults && this.searchResults.AmazonModels) {
        return [...this.searchResults.MatchingModels, ...this.searchResults.AmazonModels]
      }
      return this.searchResults?.MatchingModels || []
    },
    totalResultsCount () {
      let totalCount = (this.searchResults && this.searchResults.TotalCount) ? this.searchResults.TotalCount : 0
      if (this.searchResults && this.searchResults.AmazonModels) {
        totalCount += this.searchResults.AmazonModels.length
      }
      return totalCount
    },
    searchText () {
      let text = this.$store.getters.searchText
      if (localStorage.getItem('catalog-searchText') !== null) {
        text = localStorage.getItem('catalog-searchText')
      }
      return text
    },
    listViewResults () {
      return this.$store.getters.catalogResults
    },
    searchCategories () {
      return this.$store.getters.searchCategoryList
    },
    searchManufacturerList () {
      return this.$store.state.search.searchManufacturerList
    },
    userPermissions () {
      return this.$store.getters.userPermission
    },
    canAddModel () {
      return this.userPermissions.includes('ToolModelEdit')
    },
    hasMergedResults () {
      return this.mergedResults ? this.mergedResults.length > 0 : false
    },
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    },
    isShowOnlyTracked () {
      let onlyTracked = this.$store.getters.isShowOnlyTracked
      if (localStorage.getItem('catalog-isShowOnlyTracked') !== null) {
        onlyTracked = localStorage.getItem('catalog-isShowOnlyTracked') == 'true'
      }
      return onlyTracked
    },
    catalogSearchType () {
      return this.$store.getters.catalogSearchType
    },
    isDetailPage () {
      return this.$route.path.includes('catalog') && this.$route.path.includes('detail')
    },
    isResultPage () {
      return this.$route.path.includes('catalog') && this.$route.path.includes('search')
    },
    isDashboard () {
      return this.$route.path.includes('/dashboard')
    },
    isHomePage () {
      return this.$route.path.includes('catalog') && !this.isDetailPage && !this.isResultPage
    },
    activeColor () {
      return this.$store.getters.secondaryColor
    },
    isGrid () {
      let grid = this.$store.getters.isGrid
      if (localStorage.getItem('catalog-isGrid') !== null) {
        grid = localStorage.getItem('catalog-isGrid') == 'true'
      }
      return grid
    },
    actionHeaders () {
      const actionArrays = Object.entries(this.$t('catalog.subActionHeaderForCatalogHomeAndResult'))
      if (this.isHomePage) {
        actionArrays.splice(0, 1)
        return actionArrays
      }
      return actionArrays
    }
  },
  watch: {
    $route (to) {
      if (to.path.includes('/catalog/search')) {
        this.initSearchKey()
        this.$store.commit('setLoading', true)
        this.parseUrl()
        this.setFilter()
      }
    },
    async isShowOnlyTracked (newVal) {
      this.$store.commit('setSearchCategoryList', [])
      this.$store.commit('setSearchManufacturerList', [])
      delete this.$route.query.masterId
      delete this.$route.query.CatalogModelId
      this.pageNumber = 1
      try {
        await this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            pageNumber: this.pageNumber,
            manufacturer: '',
            category: ''
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.$nextTick(() => {
          // Categories only show if isShowOnlyTracked is true.
          if (newVal === true) {
            this.$refs.categoryFilter && this.$refs.categoryFilter.resetCategoryAndManufacturer()
          }
          this.$refs.brandFilter && this.$refs.brandFilter.resetCategoryAndManufacturer()
        })
        await this.doSearch()
      }
    },
    globalStockpointId () {
      this.doSearch()
      this.setFilter()
    }
  }
}
</script>
<style>
.search-item-holder {
  font-size: 18px;
  font-weight: bold;
}
.body-small .search-item-holder {
  font-size: 16px;
}
</style>
