const admin = {
  sensors: {
    title: 'Puertas de enlace inalámbricas',
    itemFound: 'No hay artículos | 1 sensor encontrado | {count} sensores encontrados',
    placeholder: 'Buscar en la lista',
    searchLabel: 'Buscar',
    bleEditModal: {
      title: 'Puerta de enlace inalámbrica',
      labels: {
        0: 'Localización*',
        1: 'Área',
        2: 'Tipo de puerta de enlace',
        3: 'Haz un modelo*',
        4: 'Dirección MAC*',
        5: 'Llave API',
        6: 'RSSI mínimo'
      }
    }
  },
  tags: {
    title: 'Etiquetas inalámbricas',
    placeholder: 'Buscar en la lista',
    noMsg: 'No hay resultados',
    retireTitle: 'Retirar etiquetas inalámbricas',
    confirmDelete: '¿Está seguro de que desea retirar las etiquetas inalámbricas seleccionadas?\n\nLas etiquetas retiradas vinculadas a una herramienta se desvincularán.',
    restoreTitle: 'Restaurar etiquetas inalámbricas',
    confirmRestore: '¿Restaurar {count} etiqueta{plural}?',
    showRetiredTagToggleLabel: 'Mostrar etiquetas retiradas',
    bleTagForm: {
      titleAdd: 'Agregar etiqueta inalámbrica',
      titleEdit: 'Editar  etiqueta inalámbrica',
      macInUse: 'ya está siendo utilizada por una etiqueta existente.',
      macDeleted: 'ya se utiliza.',
      alreadyLinked: 'ya está enlazado con',
      relink: '¿Enlazar artículo a esta etiqueta',
      labels: {
        0: 'Dirección MAC',
        1: 'Dirección MAC de la puerta de enlace',
        2: 'Ultima vez visto',
        3: 'Agregar manualmente',
        4: 'Agregar etiqueta escaneada',
        5: 'Modelo de etiqueta',
        6: 'Número de serie',
        7: 'Enlace al artículo',
        8: 'Desvincular',
        9: 'Escanear o introducir el artículo',
        10: 'Eliminado',
        11: 'GPS',
        12: 'Identificación única'
      },
      tooltips: {
        gpsEntitlement: 'Su contrato no tiene derecho a este campo'
      },
      validation: {
        required: '{field} es obligatorio',
        minMaxLength: '{field} debe tener un mínimo de {min} characters y un máximo de {max} caracteres',
        maxLength: '{field} debe tener como máximo {max} caracteres',
        invalidMacAddress: 'Formato de {field} no válido. Ingrese un {field} de 12 a 25 caracteres usando solo dígitos hexadecimales (0-9, A-F, a-f)'
      }
    },
    lineActions: [
      {
        title: 'Editar',
        actionName: 'editTag'
      },
      {
        title: 'Restaurar',
        actionName: 'restoreTag'
      },
      {
        title: 'Jubilarse',
        actionName: 'retireTag'
      }
    ]
  },
  changeMyPassword: {
    title: 'Cambiar la contraseña',
    labels: {
      0: 'Contraseña nueva',
      1: 'Confirmar contraseña'
    }
  },
  dataLoader: {
    title: 'Cargador de datos',
    object: 'Objeto',
    DataLoaderActions: [
      'Exportar', 'Exportar todo', 'Actualizar', 'Crear'
    ],
    DataLoaderObjects: [
      {
        key: 'Categorías',
        value: 0
      },
      {
        key: 'Certificaciones',
        value: 1
      },
      {
        key: 'Clases',
        value: 2
      },
      {
        key: 'Centro de costos',
        value: 3
      },
      {
        key: 'Contratistas',
        value: 4
      },
      {
        key: 'Clientes',
        value: 5
      },
      {
        key: 'Departamentos',
        value: 6
      },
      {
        key: 'Empleados',
        value: 7
      },
      {
        key: 'Tipos de empleados',
        value: 8
      },
      {
        key: 'Ubicaciónes',
        value: 9
      },
      {
        key: 'Fabricantes',
        value: 10
      },
      {
        key: 'Modelos',
        value: 11
      },
      {
        key: 'Clases de Servicio',
        value: 12
      },
      {
        key: 'Métodos de Envío',
        value: 13
      },
      {
        key: 'Códigos de estatus',
        value: 14
      },
      {
        key: 'Datos de ubicación de mercancias',
        value: 15
      },
      {
        key: 'Herramientas únicas',
        value: 16
      },
      {
        key: 'Unidades de medida',
        value: 17
      },
      {
        key: 'Vendedores',
        value: 18
      },
      {
        key: 'Códigos de costo',
        value: 19
      },
      {
        key: 'Cantidad de herramientas en inventario',
        value: 20
      },
      {
        key: 'Inventario de Materiales',
        value: 21
      },
      {
        key: 'Ubicación de mercancias',
        value: 22
      },
      {
        key: 'Historial de servicio',
        value: 23
      },
      {
        key: 'Herramientas de Cantidad',
        value: 24
      },
      {
        key: 'Ordenes de trabajo',
        value: 25
      },
      {
        key: 'Cargos por ordenes de trabajo',
        value: 26
      },
      {
        key: 'Cargos diversos',
        value: 27
      },
      {
        key: 'Materiales',
        value: 28
      },
      {
        key: 'Categorías de trabajos',
        value: 29
      },
      {
        key: 'Prioridades de trabajos',
        value: 30
      },
      {
        key: 'Estados de trabajos',
        value: 31
      },
      {
        key: 'Puertas de enlace inalámbricas',
        value: 32
      },
      {
        key: 'Etiquetas inalámbricas',
        value: 33
      },
      {
        key: 'Equipos',
        value: 34
      },
      {
        key: 'Herramientas únicas',
        value: 35
      }
    ],
    labels: {
      update: 'Actualizar',
      export: 'Exportar',
      exportAll: 'Exportar todo (incluye registros inactivos y eliminados)',
      create: 'Crear',
      getTemplate: 'Obtener plantilla',
      getTagModels: 'Lista de modelos de etiquetas',
      getGWModels: 'Lista de modelos de puerta de enlace',
      refresh: 'actualizar',
      feedback: 'Enviar Comentarios'
    },
    confirmDlg: {
      title: 'Confirmar actualización',
      text: 'Se actualizarán {count} {Object}. ¿Continuar?'
    }
  },
  customFields: {
    title: 'Campos Personalizados',
    placeholder: 'Buscar campos personalizados',
    editDlg: {
      title: {
        add: 'Crear campo personalizado',
        edit: 'Editar campo personalizado'
      },
      fields: {
        name: 'Nombre',
        label: 'Etiqueta',
        desc: 'Descripción',
        recordType: 'Tipo de Registro'
      }
    }
  },
  companySettings: {
    title: 'Configuración de la empresa',
    logoTitle: 'Logo de la compañía',
    logoDesc: 'El logo de su compañia se mostrará en una relación de altura a ancho de 1: 3. Para obtener los mejores resultados, utilice una imagen que sea 3 veces más ancha que alta.',
    toolLabels: {
      0: 'Períodos de deterioro de herramientas y equipos',
      1: 'Mostrar la advertencia del navegador de herramientas cuando una herramienta no ha sido escaneada, transferida o contada',
      2: 'Mostrar la advertencia del navegador de herramientas cuando una herramienta no ha sido escaneada, transferida o contada'
    },
    days: '| día | dias',
    weeks: '| semana | semanas',
    months: '| mes | meses',
    years: '| año | años',
    login: {
      0: 'Iniciar sesión',
      1: 'Longitud mínima de la contraseña',
      2: 'Intentos máximos de inicio de sesión',
      3: 'Período de bloqueo (minutos)',
      4: 'Ventana de bloqueo (minutos)',
      5: 'Mostrar Recordarme al iniciar sesión',
      6: 'Reasignación automática de activos en función de escaneos inalámbricos',
      7: 'Numeración automática',
      8: 'Hacer cumplir la numeración del sistema',
      9: 'Protección duplicada',
      10: 'Recalcular porcentajes en hojas de tarifas cuando cambia la tarifa base'
    },
    duplicatedOptions: {
      0: 'Prevenir',
      1: 'Advertencia'
    },
    twAppRequisitions: {
      0: 'Solicitudes de la aplicación ToolWatch',
      1: 'Elija el estado del boleto'
    },
    twAppUOM: {
      0: 'Valores predeterminados de la organización',
      1: 'Modelo Unidad de medida predeterminada',
      2: 'Requerir firma para la transferencia del modo de campo de aplicación',
      3: 'Habilitar el navegador de solicitudes y el flujo de trabajo de selección/compra de solicitudes',
      4: 'Costo de reemplazo de pantalla en la aplicación móvil'
    },
    pickStatusOptions: {
      0: 'Solicitado',
      1: 'Listo Para Elegir'
    },
    gpsSettings: {
      0: 'Configuración inalámbrica y GPS',
      1: 'Reasignación automática de activos en función de escaneos inalámbricos',
      2: 'Reasignar automáticamente los activos GPS según el ingreso de la geovalla',
      3: 'Después de la transferencia automática, el bloque escanea durante: ____ horas',
      4: 'Después de la transferencia manual, escanea el bloque durante: ____ horas'
    },
    tooltips: {
      0: 'El número máximo de intentos fallidos de inicio de sesión que puede realizar un usuario antes de ser bloqueado. Pre-establecido en cero permite intentos ilimitados.',
      1: 'Se bloqueará a un usuario durante el período de bloqueo si supera el número de intentos de inicio de sesión dentro de la ventana de bloqueo.',
      2: 'El tiempo que tiene un usuario entre su primer intento fallido y el intento de inicio de sesión actual antes de ser bloqueado. Un usuario que exceda el número máximo de intentos de inicio de sesión durante este período de bloqueo será bloqueado durante el período de bloqueo. Un inicio de sesión exitoso restablece este período.',
      3: 'Los tickets de solicitud creados por la aplicación ToolWatch tendrán el estado seleccionado. (\'Ready to Pick\' no se pueden modificar.)',
      4: 'Los elementos del panel personalizado se crean utilizando informes personalizados. Seleccione el informe desde el que desea crear y luego seleccione el tipo de visualización.',
      5: 'Establezca un valor de tiempo (horas) para pausar otras transferencias automáticas para su procesamiento después de que se produzca una transferencia automática.',
      6: 'Establezca un valor de tiempo (horas) para pausar otras transferencias automáticas para su procesamiento después de que se produzca una transferencia procesada manualmente.'
    }
  },
  companyCalendar: {
    title: 'Calendario predeterminado de la empresa'
  },
  integrationSettings: {
    title: 'Mis Ajustes',
    placeholder: 'Búsqueda de configuración ...'
  },
  configureUserRoles: {
    title: 'Administración de Roles',
    labels: {
      0: 'Rol',
      1: 'Nombre de rol'
    },
    tabs: [
      {
        description: 'Herramientas y Equipamiento',
        moduleId: 2
      },
      {
        description: 'Materiales y Consumibles',
        moduleId: 3
      },
      {
        description: 'Solicitudes y Transferencias',
        moduleId: 4
      },
      {
        description: 'Servicio y Calibración',
        moduleId: 5
      },
      {
        description: 'Compra y Recepción',
        moduleId: 6
      },
      {
        description: 'Costo y Facturación',
        moduleId: 7
      },
      {
        description: 'Reportes y Gráficos',
        moduleId: 8
      },
      {
        description: 'Mantenimiento de lista',
        moduleId: 9
      },
      {
        description: 'Sistema',
        moduleId: 1
      }
    ],
    deleteWarning: {
      title: 'Eliminar rol',
      message: 'Los siguientes usuarios deben eliminarse de esta función antes de la eliminación: \n {users}'
    }
  },
  numbering: {
    title: 'Numeración',
    labels: {
      0: 'Tipo de número',
      1: 'Número de herramienta',
      2: 'Número material',
      3: 'Número de Equipo',
      4: 'Número de sistema utilizado por última vez',
      5: 'Número de usuario utilizado por última vez',
      6: 'Actual',
      7: 'Historial'
    },
    types: [
      {
        key: 'Código de barras',
        value: 1
      },
      {
        key: 'Número de certificacion',
        value: 2
      },
      {
        key: 'Número de clase',
        value: 3
      },
      {
        key: 'Número de contratista',
        value: 4
      },
      {
        key: 'Número de centro de costo',
        value: 5
      },
      {
        key: 'Número de códigos de costo',
        value: 6
      },
      {
        key: 'Número de cliente',
        value: 7
      },
      {
        key: 'Número de departamento',
        value: 9
      },
      {
        key: 'Número de empleado',
        value: 10
      },
      {
        key: 'Número de tipos de empleado',
        value: 11
      },
      {
        key: 'Número de herramienta',
        value: 12
      },
      {
        key: 'Número de ubicación',
        value: 13
      },
      {
        key: 'Número de Categoría',
        value: 14
      },
      {
        key: 'Número de Modelo',
        value: 15
      },
      {
        key: 'Número de Categoría de Material',
        value: 16
      },
      {
        key: 'Número de Material',
        value: 17
      },
      {
        key: 'Número de Fabricate',
        value: 18
      },
      {
        key: 'Número de Solicitud',
        value: 19
      },
      {
        key: 'Número de Solicitud de Servicio',
        value: 20
      },
      {
        key: 'Número de Códigos de Estado',
        value: 21
      },
      {
        key: 'Número de Plantilla',
        value: 22
      },
      {
        key: 'Número de Transferencia',
        value: 23
      },
      {
        key: 'Número de unidades',
        value: 24
      },
      {
        key: 'Número de vendedor',
        value: 25
      },
      {
        key: 'Número de ordenes de trabajo',
        value: 26
      },
      {
        key: 'Número de boleto de Recepción',
        value: 27
      },
      {
        key: 'Número de orden de compra',
        value: 28
      },
      {
        key: 'Número de Equipo',
        value: 29
      },
      {
        key: 'Número de métodos de Envío',
        value: 30
      },
      {
        key: 'Número de clases de servicio',
        value: 31
      },
      {
        key: 'Número de boleto de conteo',
        value: 32
      }
    ],
    noMsg: 'No se encontraron registros coincidentes',
    placeholder: 'Búsqueda de números...'
  },
  description: {
    title: 'Descripciones',
    placeholder: 'Descripciones de búsqueda',
    labels: {
      0: 'Todos',
      1: 'Material',
      2: 'Trabajos de calibración',
      3: 'Cobro',
      4: 'Trabajo de inspección',
      5: 'Solicitud de servicio',
      6: 'Trabajo de servicio',
      7: 'Plantilla',
      8: 'Herramienta'
    },
    editDlg: {
      title: {
        0: 'Crear descripción',
        1: 'Editar descripción',
        2: 'Eliminar descripción'
      },
      labels: {
        0: 'Tipo',
        1: 'Seleccione tipo',
        2: 'Descripción'
      }
    },
    error: 'Esta descripción está en uso y no se puede eliminar.'
  },
  categories: {
    title: 'Categorías',
    labels: {
      0: 'Categorías de herramientas',
      1: 'Categorías de materiales',
      2: 'Categorías de plantillas'
    },
    placeholder: 'Categorías de búsqueda',
    editDlg: {
      title: {
        0: 'Crear ',
        1: 'Editar ',
        2: 'Eliminar '
      },
      labels: {
        0: 'Número de categoría',
        1: 'Número',
        2: 'Descripción',
        3: 'Nombre'
      }
    },
    categoryTypeTitles: {
      0: 'Categoría de herramientas y equipos',
      1: 'Categoría de materiales y consumibles',
      2: 'Categoría de plantilla'
    }
  },
  tasksCategories: {
    title: 'Categorías de trabajos',
    placeholder: 'Busqueda de Categorías de Trabajos',
    editDlg: {
      title: {
        0: 'Crear categoría de Trabajos',
        1: 'Editar categoría de Trabajos',
        2: 'Eliminar categoría de Trabajos'
      },
      labels: {
        0: 'Número de categoría',
        1: 'Descripción'
      }
    }
  },
  tasksPriorities: {
    title: 'Prioridades de Trabajos',
    placeholder: 'Prioridades de Trabajos de búsqueda',
    editDlg: {
      title: {
        0: 'Creación Prioridad de trabajos',
        1: 'Editar Prioridad de trabajos',
        2: 'Eliminar Prioridad de trabajos'
      },
      labels: {
        0: 'Número de prioridad',
        1: 'Descripción'
      }
    }
  },
  tasksStatuses: {
    title: 'Estados de trabajos',
    placeholder: 'Buscar estados de trabajos',
    editDlg: {
      title: {
        0: 'Crear estado de trabajos',
        1: 'Editar estado de trabajos',
        2: 'Borrar estado de trabajos'
      },
      labels: {
        0: 'Número de estado',
        1: 'Descripción'
      }
    }
  },
  draft: {
    title: 'Borrador no guardado',
    body: 'Hay un boleto sin guardar en estado Borrador. ¿Está seguro de que desea cancelar el borrador existente y crear un nuevo boleto?'
  },
  doNotTransfer: {
    title: 'No transferir',
    multipleItemMessage: 'Hay varios elementos con un estado de \'No transferir\'. ¿Desea anular todos los elementos?',
    singleItemMessage: '{itemNumber} - {description} tiene un estado de \'No transferir\'. ¿Desea anular?'
  },
  editDashboard: {
    title: 'Añadir elemento',
    saveChangs: 'Guardar cambios',
    builder: 'Constructora',
    chartTitle: 'Título',
    report: 'Informe personalizado',
    displayAs: 'Mostrar como',
    xAxis: 'eje x',
    yAxis: 'eje y',
    series: 'Serie',
    preview: 'Presentacion',
    xAxisValue: 'Seleccione el valor del eje X',
    yAxisValue: 'Seleccione el valor del eje Y',
    selectReport: 'Seleccione un Reporte',
    invalidData: 'El reporte que ha seleccionado no es compatible con este objeto visual, seleccione otro reporte o un objeto visual diferente',
    titleMessage: 'Ingrese el título',
    reportMessage: 'Seleccione un reporte',
    displayAsMessage: 'Seleccione Mostrar como',
    seriesMessage: 'Seleccione una serie',
    selectMeasure: 'Medida',
    displayUnits: 'Unidades de visualización',
    lowRangeBreak: 'Ruptura de rango bajo',
    highRangeBreak: 'Ruptura de rango alto',
    checkMetricValue: 'El valor metrico no debe estar en blanco ni ser cero.',
    customDashboardSuccess: 'El panel personalizado se ha guardado correctamente.',
    label: 'Etiqueta',
    labelValue: 'Seleccionar valor de etiqueta',
    stacking: 'Apilado',
    customDashboardAssigned: 'Los paneles personalizados se han asignado correctamente.',
    columns: 'Columnas',
    groupBy: 'Agrupar por',
    sort: 'Clasificar',
    maxDisplayed: 'Máximo mostrado',
    maxDisplayedSetting: {
      0: '10',
      1: '20',
      2: '50',
      3: '100'
    },
    sorting: {
      0: 'Ascendente',
      1: 'Descendente'
    },
    groupByValue: 'Seleccione Valor por Agrupar',
    sortValue: 'Seleccione Valor por Ordenar',
    maxDisplayedValue: 'Seleccione el valor máximo para mostrar',
    columnsMessage: 'Seleccionar columnas',
    barChartMessage: `El eje Y y la serie seleccionados no son compatibles
      entre sí. Seleccione una combinación diferente.`,
    standardChartMessage: `El eje X y la Serie seleccionados no son compatibles
      entre sí. Seleccione una combinación diferente.`,
    pieChartMessage: `La Etiqueta y Serie seleccionadas no son compatibles
      entre sí. Seleccione una combinación diferente.`,
    metricChartMessage: `Las unidades de medida y visualización seleccionadas no son compatibles
      entre sí. Seleccione una combinación diferente.`,
    group: ' Grupo',
    filter: 'Filtrar'
  },
  customDashboardChartType: {
    lineChartId: '9aee7d0a-d423-436a-bcb6-952d999f852a',
    metricChartId: '60c1e068-40dd-4f58-a8eb-464a6d307cfb',
    barChartId: '7ecde5ca-6601-4eb6-91a5-287d3c8cd375',
    pieChartId: 'd368a578-f4db-4d65-9217-d060272391e8',
    tableChartId: '6e6e513e-9cbf-4751-a959-b57c06783073'
  },
  customDashboardChartTypeName: {
    lineChart: 'LineChart',
    metricChart: 'MetricChart',
    barChart: 'BarChart',
    pieChart: 'PieChart',
    columnChart: 'ColumnChart',
    tableChart: 'TableChart'
  },
  customDashboardPages: {
    systemDashboard: '4598634b-16be-4419-9468-4f253eeb19a0',
    purchasingDashboard: '4aa7fa0e-758d-4794-9176-157260765cbf',
    serviceDashboard: 'd6f0e888-c2ad-4775-987b-8c32808b0643',
    pickTicketsTransfersDashboard: '79755f5c-f27f-4509-b917-ca9c42872ded'
  },
  certifications: {
    title: 'Certificaciones',
    placeholder: 'Buscar Certificationes',
    editDlg: {
      title: {
        0: 'Crear certificaciones',
        1: 'Editar certificación',
        2: 'Eliminar certificación',
        3: 'Certificación de clonación'
      },
      labels: {
        0: 'Código',
        1: 'Descripción',
        2: 'Renovar cada',
        3: 'Notas'
      }
    }
  },
  classes: {
    title: 'Clases',
    placeholder: 'Clases de búsqueda',
    editDlg: {
      title: {
        0: 'Crear ',
        1: 'Editar ',
        2: 'Eliminar '
      },
      labels: {
        0: 'Numero de clase',
        1: 'Nombre'
      }
    }
  },
  contractors: {
    title: 'Contratistas',
    placeholder: 'Buscar contratistas',
    editDlg: {
      title: {
        0: 'Crear contratista',
        1: 'Editar contratista',
        2: 'Eliminar contratista'
      },
      labels: {
        0: 'No.',
        1: 'Nombre',
        2: 'Cuenta No.',
        3: 'Dirección 1',
        4: 'Dirección 2',
        5: 'Ciudad',
        6: 'Estado',
        7: 'Código Postal',
        8: 'País',
        9: 'Contacto',
        10: 'Correo electrónico',
        11: 'Teléfono',
        12: 'Teléfono alterno',
        13: 'Fax',
        14: 'Direccion web',
        15: 'Observaciones'
      }
    }
  },
  customers: {
    title: 'Clientes',
    placeholder: 'Buscar clientes',
    editDlg: {
      title: {
        0: 'Crear cliente',
        1: 'Editar cliente',
        2: 'Eliminar cliente'
      },
      labels: {
        0: 'Número de cliente',
        1: 'Nombre',
        2: 'Dirección 1',
        3: 'Dirección 2',
        4: 'Ciudad',
        5: 'Estado',
        6: 'Código Postal',
        7: 'País',
        8: 'Contacto',
        9: 'Teléfono',
        10: 'Teléfono alterno',
        11: 'Notas'
      }
    }
  },
  departments: {
    title: 'Departamentos',
    placeholder: 'Departamentos de búsqueda',
    editDlg: {
      title: {
        0: 'Crear departamento',
        1: 'Editar departamento',
        2: 'Eliminar departamento'
      },
      labels: {
        0: 'Número',
        1: 'Descripción',
        2: 'Supervisor',
        3: 'Teléfono',
        4: 'Fax'
      }
    }
  },
  employees: {
    title: 'Empleados',
    fuelTitle: 'Empleados de combustible',

    placeholder: 'Buscar empleados',
    actionHeader: {
      0: {
        title: 'importar empleados',
        icon: 'fas fa-fas fa-file-export'
      }
    },
    labels: {
      0: 'Todas',
      1: 'Activo',
      2: 'Inactivo',
      3: 'Estado'
    },
    editDlg: {
      title: {
        0: 'Crear empleado',
        1: 'Editar empleado',
        2: 'Eliminar empleado'
      },
      labels: {
        0: 'Primer nombre',
        1: 'Apellido',
        2: 'ID de empleado',
        3: 'Código de barras'
      },
      tabs: {
        0: {
          title: 'Info general',
          labels: {
            0: 'Ubicación de stock asignada',
            1: 'Dirección del empleado 1',
            2: 'Dirección del empleado 2',
            3: 'Ciudad',
            4: 'Estado',
            5: 'Código Postal',
            6: 'País',
            7: 'Teléfono1',
            8: 'Teléfono2',
            9: 'Correo electrónico',
            10: 'Inactivo',
            11: 'Fecha inactiva',
            12: 'No transferir',
            13: 'Razón',
            14: 'Recordatorio de baja'
          }
        },
        1: {
          title: 'Info del empleado',
          labels: {
            0: 'Departamentos',
            1: 'Supervisor',
            2: 'Tipos de empleados',
            3: 'Fecha de contratación',
            4: 'Fecha de conclusión',
            5: 'EmpleaItem Descriptiondo',
            6: 'Contratista',
            7: 'Usuario de Tarjeta de combustible'
          }
        },
        2: {
          title: 'Info de facturación',
          labels: {
            0: 'Centro de costos',
            1: 'Código de costo'
          }
        },
        3: {
          title: 'Certificaciones'
        },
        4: {
          title: 'Notas',
          labels: {
            0: 'Notas',
            1: 'Notas: Máximo 4000 caracteres ({count} demasiados)'
          }
        },
        5: {
          title: 'Info de registro'
        },
        customFields: {
          title: 'Campos personalizados'
        }
      }
    },
    manageTWUser: {
      title: 'Administración de cuentas de usuario',
      accessLevel: {
        0: 'Acceso de usuario',
        1: 'Escritorio',
        2: 'Almacén móvil',
        3: 'Campo móvil',
        4: 'Cloud'
      },
      formLabels: {
        password: {
          0: 'Correo electrónico del usuario',
          1: 'Correo electrónico existente',
          2: 'Nuevo correo electrónico',
          3: 'Dirección de correo electrónico',
          4: 'Contraseña ',
          5: 'Correo electrónico y contraseña de usuario',
          6: 'Cambiar la dirección de correo electrónico',
          7: 'Cambiar la contraseña',
          8: 'confirmar Contraseña',
          9: 'La contraseña debe tener al menos 8 caracteres',
          10: 'Los campos de contraseña deben coincidir'
        },
        roles: {
          0: 'Roles disponibles',
          1: 'Deben seleccionarse uno o más roles.'
        },
        deactivateWarning: {
          title: 'Desactivar el usuario?',
          message: '¿Está seguro de que desea desactivar esta cuenta? El usuario ya no tendrá acceso al sistema ToolWatch.'
        },
        confirmDialog: {
          confirmDlgTitle: 'Administración de cuentas de usuario',
          message: 'Los cambios a este registro de empleado se guardarán antes de editar la cuenta de usuario.'
        }
      }
    }
  },
  employeeTypes: {
    title: 'Tipos de empleados',
    placeholder: 'Buscar tipos de empleados',
    editDlg: {
      title: {
        0: 'Crear tipo de empleado',
        1: 'Editar tipo de empleado',
        2: 'Eliminar tipo de empleado'
      },
      labels: {
        0: 'Código',
        1: 'Descripción'
      }
    }
  },
  locations: {
    title: 'Ubicaciones',
    placeholder: 'Ubicaciones de búsqueda',
    labels: {
      0: 'Todas',
      1: 'Activo',
      2: 'Inactivo',
      3: 'Ser dado de baja',
      4: 'Estado'
    },
    tabs: {
      0: 'Detalle de ubicación',
      1: 'Opciones de factura',
      2: 'Campos personalizados',
      3: 'Mapa'
    },
    editDlg: {
      title: {
        0: 'Crear ubicación',
        1: 'Editar ubicación',
        2: 'Eliminar ubicación'
      },
      labels: {
        0: 'Ubicación principal',
        1: 'Esta es una ubicación de stock.',
        2: 'Número de ubicación',
        3: 'Código de barras',
        4: 'Descripción',
        5: 'Dirección',
        6: 'Ciudad',
        7: 'País',
        8: 'Estado',
        9: 'Código Postal',
        10: 'Teléfono de ubicación',
        11: 'Fax',
        12: 'Supervisor',
        13: 'Centro de costos',
        14: 'Código de costo',
        15: 'Observaciones',
        16: 'Inactivo',
        17: 'Fecha',
        18: 'No se puede transferir a esta ubicación',
        19: 'Razón',
        20: 'Recordatorio de baja',
        21: 'Latitud, Longitud'
      },
      invoiceOptions: {
        0: 'Unidad de negocio',
        1: 'Condiciones',
        2: 'Impuesto %',
        3: 'instrucciones especiales',
        4: 'Mensaje de reclamo',
        5: 'Dirección de remesa',
        6: 'La dirección de remesa es la misma que la ubicación',
        7: 'Nombre',
        8: 'Dirección',
        9: 'Dirección 2',
        10: 'Ciudad',
        11: 'Estado',
        12: 'Código Postal',
        13: 'País'
      },
      map: {
        0: 'Habilitar Geocerca',
        1: 'Radio De Geofeno',
        2: 'Unidad de medida'
      },
      cannotChangeParent: 'No puede cambiar la posición de {description} porque es la parte superior de su árbol de organización',
      latLongValidation: 'La latitud y la longitud deben contener dos valores numéricos positivos o negativos separados por una coma.',
      geofenceValidation: 'El radio mínimo no puede ser inferior a {0} {1}'
    },
    dialogLabels: {
      saveLocationInactive: 'No se puede desactivar la ubicación',
      inactiveMessage: 'Esta ubicación no se puede desactivar porque se le asignan {0} ubicaciones. Elimine o haga que esas ubicaciones estén inactivas primero.'
    },
    geoFenceUoms: [
      {
        key: 'meters',
        description: 'Metros',
        conversionToMeters: 1,
        minValue: 50
      },
      {
        key: 'feet',
        description: 'Pies',
        conversionToMeters: 0.3048,
        minValue: 150
      },
      {
        key: 'yards',
        description: 'Yardas',
        conversionToMeters: 0.9144,
        minValue: 50
      },
      {
        key: 'miles',
        description: 'Millas',
        conversionToMeters: 1609.34,
        minValue: 0.03
      }
    ]
  },
  manufacturers: {
    title: 'Fabricantes',
    placeholder: 'Buscar fabricantes',
    editDlg: {
      title: {
        0: 'Crear fabricante',
        1: 'Editar fabricante',
        2: 'Eliminar fabricante'
      },
      labels: {
        0: 'Número',
        1: 'Nombre',
        2: 'Teléfono',
        3: 'Fax',
        4: 'Prefijo UPC',
        5: 'Dirección web'
      }
    }
  },
  numberBlocks: {
    title: 'Reservas de número de punto de almacenamiento',
    placeholder: 'Bloques de números de búsqueda',
    editDlg: {
      title: {
        0: 'Crear reservas de número de punto de almacenamiento',
        1: 'Editar reservas de número de punto de almacenamiento',
        2: 'Eliminar reservas de número de almacenamiento'
      },
      labels: {
        0: 'Tipo de número',
        1: 'Tamaño de bloque',
        2: 'Número actual'
      }
    }
  },
  dataMergeUtility: {
    title: 'Fusion de Datos'
  },
  shippingMethods: {
    title: 'Métodos de envío',
    placeholder: 'Buscar métodos de envío',
    editDlg: {
      title: {
        0: 'Crear método de envío',
        1: 'Editar método de envío',
        2: 'Eliminar método de envío'
      },
      labels: {
        0: 'Número',
        1: 'Nombre'
      }
    }
  },
  statusCodes: {
    title: 'Códigos de estado',
    placeholder: 'Buscar codigos de estado',
    editDlg: {
      title: {
        0: 'Crear código de estado',
        1: 'Editar código de estado',
        2: 'Eliminar código de estado'
      },
      labels: {
        0: 'Código',
        1: 'Descripción',
        2: 'No se puede transferir ni mover',
        3: 'Cumplir con el servicio o la reparación',
        4: 'Destruido sin reparación',
        5: 'Será retirado y enviado a objetos perdidos'
      }
    }
  },
  unitsOfMeasures: {
    title: 'Unidades de medida',
    placeholder: 'Buscar unidades de medida',
    editDlg: {
      title: {
        0: 'Crear unidad de medida',
        1: 'Editar unidad de medida',
        2: 'Eliminar unidad de medida'
      },
      labels: {
        0: 'Unidad',
        1: 'Descripción'
      }
    },
    notDeleteUOMMsg: 'Esta es una unidad de medida predeterminada para su organización. Se debe cambiar el valor predeterminado antes de eliminar esta unidad de medida.',
    notEditUOMMsg: 'Esta es una unidad de medida predeterminada para su organización. El valor predeterminado debe cambiarse antes de editar esta unidad de medida.'
  },
  vendors: {
    title: 'Vendedores',
    placeholder: 'Proveedores de búsqueda',
    editDlg: {
      title: {
        0: 'Crear proveedor',
        1: 'Editar proveedor',
        2: 'Eliminar proveedor'
      },
      labels: {
        0: 'Número',
        1: 'Nombre',
        2: 'Inactivo'
      },
      tabs: {
        0: {
          title: 'Información del proveedor',
          labels: {
            0: 'Dirección',
            1: 'Ciudad',
            2: 'Estado',
            3: 'Código Postal',
            4: 'País',
            5: 'Dirección Web',
            6: 'Observaciones'
          }
        },
        1: {
          title: 'Datos de contacto',
          labels: {
            0: 'Contacto',
            1: 'Correo electrónico',
            2: 'Teléfono',
            3: 'Móvil',
            4: 'FAX'
          }
        },
        2: {
          title: 'Informacion de cuenta',
          labels: {
            0: 'Cuenta No.',
            1: 'Método de envío',
            2: 'Al imprimir el informe de discrepancias para este proveedor, permita esta variación de precio antes de agregar artículos al informe.',
            3: 'Diferencia de discrepancia'
          }
        },
        3: {
          title: 'Campos personalizados',
          labels: {
          }
        }
      }
    }
  },
  costCenters: {
    title: 'Centros de costos',
    placeholder: 'Buscar centros de costos',
    editDlg: {
      title: {
        0: 'Crear centro de costos',
        1: 'Editar centro de costos',
        2: 'Eliminar centro de costos'
      },
      labels: {
        0: 'No.',
        1: 'Descripción'
      },
      tabs: {
        0: {
          title: 'Información general',
          labels: {
            0: 'Gerente',
            1: 'Cliente',
            2: 'Fecha de inicio',
            3: 'Fecha final',
            4: 'Grupo de código',
            5: 'Habilitar códigos de costo globales',
            6: 'Inactivo'
          }
        },
        1: {
          title: 'Tarifas de proyecto',
          labels: {
            0: 'Hoja de tarifas de herramientas y equipos',
            1: 'Hoja de tarifas de materiales y consumibles'
          }
        },
        2: {
          title: 'Reglas de facturación del proyecto',
          labels: {
            0: 'Utilice este pedido al emitir créditos por cantidad de herramientas devueltas:',
            1: 'USPE (último en salir, primero en entrar)',
            2: 'PSPE (primero en salir, primero en entrar)'
          }
        },
        3: {
          title: 'Calendario de proyectos',
          labels: {
            0: 'Herramientas devueltas antes',
            1: 'no se facturan por ese día.',
            2: 'Herramientas asignadas después'
          }
        },
        4: {
          title: 'Notas',
          labels: {
            0: 'Notas'
          }
        },
        customFields: {
          title: 'Campos Personalizados',
          labels: {
          }
        }
      }
    }
  },
  costCodes: {
    title: 'Códigos de costo',
    placeholder: 'Códigos de costo de búsqueda',
    editDlg: {
      title: {
        0: 'Crear código de costo',
        1: 'Editar código de costo',
        2: 'Eliminar código de costo'
      },
      labels: {
        0: 'Código',
        1: 'Descripción',
        2: 'Centro de costos',
        3: 'Grupo de código',
        4: 'Inactivo'
      }
    }
  },
  serviceClasses: {
    title: 'Clases de servicio',
    placeholder: 'Clases de servicio de búsqueda',
    editDlg: {
      title: {
        0: 'Crear clase de servicio',
        1: 'Editar clase de servicio',
        2: 'Eliminar clase de servicio'
      },
      labels: {
        0: 'Número',
        1: 'Nombre'
      }
    }
  },
  taggedForm: {
    title: {
      0: 'Renovación',
      1: 'Información de registro'
    },
    labels: {
      0: 'Creado',
      1: 'Por',
      2: 'Última modificación'
    }
  },
  pagingLabel: {
    0: 'Mostrando',
    1: 'para',
    2: 'de',
    3: 'filas'
  },
  userPreferences: {
    title: 'Preferencias del usuario',
    notificationsSection: {
      title: 'Notificaciones',
      verifiedPhoneNumber: 'Número de teléfono verificado para notificaciones por SMS:',
      noVerifiedPhoneNumber: 'No se estableció ningún número de teléfono verificado',
      notifyMeWhen: 'Notifícame cuando...',
      byEmail: 'Por email...',
      bySms: 'Por SMS...',
      pickTicketRequested: 'Los boletos de picking se solicitan en una ubicación que superviso',
      pickTicketReadyToPick: 'Los boletos de picking están listos para elegir en una ubicación que superviso',
      pickTicketCompleted: 'Elija los boletos solicitados por mí están completados',
      assetsTransferredToLocation: 'Los activos se transfieren a una ubicación que superviso',
      assetsTransferredToMe: 'Los activos me son transferidos',
      workOrderCreated: 'Órdenes de trabajo creadas en una asignación que superviso',
      serviceRequestCreated: 'Solicitud de servicio creada en una tarea que superviso',
      assignedResponsibleParty: 'Estoy asignado como responsable de una herramienta',
      clearedResponsibleParty: 'Estoy autorizado como responsable de una herramienta',
      assetsReinstated: 'Los activos se restablecen en una ubicación que superviso',
      employeeSuperviseCertification: 'Los empleados que superviso tienen una certificación que se ha vencido',
      toolsDueForReturnToday: 'Los activos deben devolverse hoy desde una ubicación que superviso',
      toolsPastDueForReturn: 'Los activos que deben devolverse en los últimos 30 días desde una ubicación que superviso están vencidos',
      completedCalcs: 'Cálculos de costos y facturación del trabajo que comencé y completé',
      locationTitle: 'Notificaciones específicas de ubicación',
      locationNotification: 'El usuario no tiene que estar configurado como supervisor de ubicación para recibir la notificación',
      approvalNotification: 'tengo aprobaciones abiertas',
      outOfBondNotification: 'Los activos con GPS están fuera de los límites en una ubicación que superviso',
      inBondsNotification: 'Los artículos con GPS han llegado a un lugar que superviso',
      retiredToolNotification: 'La herramienta retirada ha sido escaneada en una ubicación que superviso',
      requisitionBrowserNotification: 'Solicitud presentada para una ubicación que superviso'
    },
    locationNotificationSection: {
      pickTicketRequested: 'Recoger ticket solicitados',
      pickTicketReadyToPick: 'Elija boletos listos',
      pickTicketCompleted: 'Recoger boletos completados',
      assetsTransferredToLocation: 'Activos transferidos a la ubicación',
      workOrderCreated: 'Orden de trabajo creada',
      assetsReinstated: 'Activos reintegrados',
      toolsDueForReturnToday: 'Los activos en la ubicación vencen hoy',
      toolsPastDueForReturn: 'Los activos en la ubicación están vencidos',
      locationHeader: 'Ubicación',
      blankWrngMsg: '*Seleccione una ubicación para configurar notificaciones específicas de la ubicación.',
      userLocationPreferencesbtn: 'Notificaciones de usuario',
      locationPreferencesbtn: 'Notificaciones de ubicación',
      outOfBondNotification: 'Los activos con GPS están fuera de los límites de una ubicación',
      inBondsNotification: 'Los elementos con GPS han llegado a una ubicación',
      serviceRequestCreated: 'Solicitud de servicio creada',
      retiredToolNotification: 'La herramienta retirada ha sido escaneada',
      requisitionBrowserNotification: 'Requisición enviada'
    },
    expressCheckInSection: {
      title: 'Registrarse exprés',
      duringExpressCheckIn: 'Durante el registrarse exprés...',
      presentTransferItemsAs: 'Todos los elementos de transferencia deben presentarse inicialmente como',
      checked: 'Comprobado',
      unchecked: 'Desenfrenado'
    },
    smsPopups: {
      sendVerificationCodeMessage: 'Ingrese su número de teléfono para recibir notificaciones de texto (SMS). Le enviaremos un código de verificación por única vez. Se pueden aplicar tarifas por mensajes y datos.',
      sendVerificationCodeButton: 'Envíe el código de verificación',
      countryCode: 'Código de país',
      phoneNumber: 'Número de teléfono',
      enterPhoneNumber: 'Ingresa número telefónico',
      enterVerificationCode: 'Ingrese el código de verificación',
      verifyCode: 'Código de verificación',
      verificationCode: 'Código de verificación',
      weSentATextMessage: 'Acabamos de enviar un mensaje de texto a',
      withAOneTimeCode: 'con un código de verificación único. Ingrese ese código aquí para verificar su número de teléfono.'
    },
    toolTip: {
      notificationsSection: {
        pickTicketRequested: 'Notificacion activada Cuando Boletos de Solicitud Nuevos estan en el estado de Listo Para Seleccionar en La Ubicacion en la Cual esta establecido Como Supervisor',
        pickTicketReadyToPick: 'Notificacion activada Cuando Boletos de Solicitud Nuevos estan Sometidos en el estado de requerido en La Ubicacion en la Cual esta establecido Como Supervisor',
        pickTicketCompleted: 'Notificacion activada Cuando Solicitudes que he sometido han sido completadas',
        assetsTransferredToLocation: 'Notificacion activada cuando nuevos activos han sido transferido a tu propiedad',
        assetsTransferredToMe: 'Notificacion activada cuando nuevos activos han sido transferidos a una Ubicacion a la Cual estoy establecido como supervisor',
        workOrderCreated: 'Notificacion activada cuando ordenes de trabajo han sido creadas en ubicaciones en la cual se ha establecido como Supervisor',
        serviceRequestCreated: 'Notificacion activada Cuando una Solicitud de servicio Nueva ha sido creada en una Ubicacion a la cuala esta esablecido como Supervisor',
        assignedResponsibleParty: 'Notificacion activada cuando ha sido establecido como Personal Responsable de un Articulo',
        clearedResponsibleParty: 'Notificacion activada cuando se ha removido comom Personal Reponsable de un Articulo',
        assetsReinstated: 'Notificacion activada Cuando articulos que han sido Retirados son re-establecidos en una ubicacion la cual usted esta establecido como Supervisor',
        employeeSuperviseCertification: 'Notificacion activada cuando empleados los cuales usted esta establecido como Supervisor tienen certificaciones expiradas',
        toolsDueForReturnToday: 'Notificacion activada cuando articulos tienen una fecha de retorno establecida para hoy en una ubacion la cual esta establecido como Supervisor',
        toolsPastDueForReturn: 'Notificacion activada cuando articulos tienen una fecha de retorno estableciada en los ultimos 30 dias en una ubicacion la cual esta establecido como Supervisor',
        completedCalcs: 'Notificacion activada cuando calculos estan listos para ver',
        outOfBondNotification: 'Notificacion activada cuando articulos con GPS asociados son reportados fuera de los limites de la geocerca de la ubicacion asignada al articulo',
        inBondsNotification: 'Notificacion activada cuando articulos con GPS asociados son reportados dentro de los limites de la geocerca de la ubicacion asignada al articulo',
        retiredToolNotification: 'Notificacion activada cuando articulos con BLE estan en un estado de retirados es detectado por un scanner inalambrico en una ubicacion la cual esta establecido como Supervisor'
      },

      locationNotificationSection: {
        pickTicketRequested: 'Notificacion activada cuando nuevos Boletos de Solicitud son sometidos en el estado de Requerido en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        pickTicketReadyToPick: 'Notificacion activada cuando nuevos Boletos de Solicitu estan en el estado Listo para Seleccionar en de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        pickTicketCompleted: 'Notificacion activada cuando nuevos Boletos de Solicitud estan el en Estado Completado en de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        assetsTransferredToLocation: 'Notificacion activada cuando hay activos transferidos a una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        workOrderCreated: 'Notificacion activada cuando hay Ordenes de Trabajo creadas en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        assetsReinstated: 'Notificacion activada cuando articulos han sido re-establecidos de un estado Retirado en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        toolsDueForReturnToday: 'Notificacion activada cuando articulos se deben para devolucion hoy en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        toolsPastDueForReturn: 'Notificacion activada cuando hay articulos que pasna la fecha de retorno en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        outOfBondNotification: 'Notificacion activada cuando articulos con GPS associados son reportados fuera de los limites de geocerca assignados en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba',
        inBondsNotification: 'Notificacion activada cuando articulos con GPS associados son reportados dentro de los limites de geocerca de la ubicacion seleccionada en el menu deplegable por primera vez',
        retiredToolNotification: 'Notificacion activada cuando articulos con BLE con estado de retirado es reconocido por el escaneo inalambrico en una de las ubicaciones del menu deplegable de arriba',
        serviceRequestCreated: 'Notificacion activada cuando hay Solicitud de Servicios creados en una de las ubicaciones las cuales son seleccionadas del menu deplegable de arriba'
      }
    },
    feedback: 'Enviar Comentarios'
  }
}

export default admin
