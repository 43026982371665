<template>
  <ul
    id="right-click-menu"
    tabindex="-1"
    ref="rightClickMenuRef"
    :style="{ top: menuTop, left: menuLeft }"
    v-click-outside="closeContextMenu"
  >
   <template
      v-for="(action, index) in mergedActions"
    >
      <li
        :key="index"
        v-if="isActionShown(action) && !isActionDisabled(action)"
        @click="selectAction(action)"
      >
        {{action.title}}
      </li>
    </template>
  </ul>
</template>
<script>
import { deepCopy } from '../../helpers/utils'
export default {
  name: 'TWTableContextMenu',
  props: {
    selectedLength: {
      type: Number,
      required: true
    },
    pathToActions: {
      type: String,
      required: true
    },
    isActionShown: {
      type: Function,
      required: false,
      default: function () {
        return true
      }
    },
    isActionDisabled: {
      type: Function,
      required: false,
      default: function () {
        return false
      }
    },
    isShowSelectedText: {
      type: Boolean,
      required: false,
      default: true
    },
    contextMenuTop: {
      type: Number,
      required: true
    },
    contextMenuLeft: {
      type: Number,
      required: true
    },
    clientY: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      menuLeft: '',
      menuTop: ''
    }
  },
  mounted () {
    this.setRightMenu()
  },
  watch: {
    contextMenuTop: {
      handler (newValue, oldValue) {
        if (newValue != oldValue) {
          this.setRightMenu()
        }
      },
      deep: true
    }
  },
  methods: {
    setRightMenu () {
      const menu = document.getElementById('right-click-menu')
      const hightMargin = this.clientY - this.contextMenuTop
      const windowHeight = window.innerHeight - hightMargin
      const menuHeight = menu.offsetHeight
      this.menuLeft = this.contextMenuLeft + 10 + 'px'
      if (this.contextMenuTop + menuHeight > windowHeight) {
        this.menuTop = (windowHeight - menuHeight - 20) + 'px'
      } else {
        this.menuTop = this.contextMenuTop + 'px'
      }
    },
    selectAction (selectedAction) {
      if (selectedAction === null) return
      this.closeContextMenu()
      this.$emit('onActionSelected', selectedAction.actionName)
      this.$emit('onActionSelectedFull', selectedAction)
    },
    closeContextMenu () {
      this.$parent.viewContextMenu = false
    }
  },
  computed: {
    mergedActions () {
      const mergedActions = deepCopy(this.englishActions)
      if (this.englishActions) {
        if (this.englishActions.length !== this.chosenLanguageActions.length) {
          throw new Error('English action list has different number of entries than current action list')
        }
      }
      Object.keys(this.englishActions).map((key) => {
        const override = this.chosenLanguageActions?.[key]
        if (override) {
          Object.assign(mergedActions[key], override)
        }
      })
      return mergedActions
    },
    englishActions () {
      return this.$t(this.pathToActions, 'en', {})
    },
    chosenLanguageActions () {
      return this.$t(this.pathToActions)
    }
  }
}
</script>
<style scoped>
  #right-click-menu {
    background: #FAFAFA;
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 250px;
    z-index: 999999;
  }
  #right-click-menu li {
    border-bottom: 1px solid #E0E0E0;
    margin: 0;
    padding: 5px 35px;
  }
  #right-click-menu li:last-child {
    border-bottom: none;
  }
  #right-click-menu li:not(.disabled):hover {
    background: var(--primary-color);
    color: #FAFAFA;
    cursor: pointer;
  }
  #right-click-menu li.disabled {
    background: #ccc;
    color: #FAFAFA;
  }
</style>
