const kits = {
  title: 'Kit Detail',
  actionHeaders: {
    0: {
      title: 'Save',
      icon: 'fas fa-save'
    },
    1: {
      title: 'Add Tool Record',
      icon: 'fas fa-plus'
    },
    2: {
      title: 'Add Material Record',
      icon: 'fas fa-plus'
    },
    3: {
      title: 'Print',
      icon: 'fas fa-print'
    },
    4: {
      title: 'Back',
      icon: 'fas fa-arrow-rotate-left'
    }
  },
  labels: {
    kitNum: 'Kit No.',
    assignedTo: 'Assigned To',
    description: 'Description',
    category: 'Category',
    barCode: 'Bar Code',
    note: 'Note',
    detailSummary: 'Kit Detail Summary',
    kitSaveError: 'Kit Save Error',
    kitSaveErrorMsg: 'At least one item must be added before the kit can be save.'
  },
  kitItemTbl: {
    tableHeader: {
      desktop: [
        {
          label: 'T',
          key: 'itemType',
          sortable: true
        },
        {
          label: 'Category',
          key: 'category',
          sortable: true
        },
        {
          label: 'Item Number',
          key: 'itemNumber',
          sortable: true
        },
        {
          label: 'Description',
          key: 'description',
          sortable: true
        },
        {
          label: 'Qty',
          key: 'quantity',
          type: Number,
          isEditable: true,
          sortable: true
        },
        {
          label: 'Model',
          key: 'model',
          sortable: true
        },
        {
          label: 'Manufacturer',
          key: 'manufacturer',
          sortable: true
        },
        {
          label: 'Serial Number',
          key: 'serialNumber',
          sortable: true
        },
        {
          label: 'Status',
          key: 'itemStatus',
          sortable: true
        },
        {
          label: 'AssignedTo',
          key: 'assignedTo',
          sortable: true
        },
        {
          label: 'PI',
          key: 'personalIssue',
          type: Boolean,
          isEditable: false,
          sortable: true
        },
        {
          label: 'Owner',
          key: 'owner',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'T',
          key: 'itemType',
          sortable: true
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Category',
              key: 'category',
              sortable: true
            },
            {
              label: 'Item Number',
              key: 'itemNumber',
              sortable: true
            },
            {
              label: 'Description',
              key: 'description',
              sortable: true
            },
            {
              label: 'Qty',
              key: 'quantity',
              type: Number,
              isEditable: true,
              sortable: true
            },
            {
              label: 'Model',
              key: 'model',
              sortable: true
            },
            {
              label: 'Manufacturer',
              key: 'manufacturer',
              sortable: true
            },
            {
              label: 'Serial Number',
              key: 'serialNumber',
              sortable: true
            },
            {
              label: 'Status',
              key: 'itemStatus',
              sortable: true
            },
            {
              label: 'AssignedTo',
              key: 'assignedTo',
              sortable: true
            },
            {
              label: 'PI',
              key: 'personalIssue',
              type: Boolean,
              isEditable: false,
              sortable: true
            },
            {
              label: 'Owner',
              key: 'owner',
              sortable: true
            }
          ]
        }
      ]
    }
  },
  print: {
    noItems: 'No items'
  },
  kitItemTblActions: [
    {
      title: 'View',
      actionName: 'viewLine',
      permissions: []
    },
    {
      title: 'Remove Line',
      actionName: 'removeLine',
      permissions: []
    }
  ]
}

export default kits
